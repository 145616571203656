import { Button, Collapse, DatePicker, Select } from "antd";
import SelectInput from "../../common/SelectInput";
import styled from "styled-components";
import { Wrapper } from "../../elements/Styled";
import { useAppContext } from "../../../context/AppContext";
import { setPickerDate } from "../../../utils/helperUtil";
import { GetPermissions } from "../../../utils/permissionsUtils";
import {
  APP_CAN_ADMIN,
  APP_CAN_APPROVE,
  CAN_ADMIN,
  CAN_DASHBOARD,
  CAN_DIVISION,
  CAN_PHYSICAL_PLANNING,
  CAN_PHYSICAL_PLANNING_DIVISION,
} from "../../../constants/webapp";
export const dateFormat = "YYYY-MM-DD";

export const handleReportFilter = (form, setFilter) => {
  let params = "";
  // municipality
  if (form?.municipality) {
    params = `${params}municipality=${form?.municipality}`;
  }
  // division
  if (form?.division) {
    params = `${params}&division=${form?.division}`;
  }
  // parish
  if (form?.parish) {
    params = `${params}&parish=${form?.parish}`;
  }
  // fin year
  if (form?.fin_year) {
    params = `${params}&fin_year=${form?.fin_year}`;
  }
  // Satrt date
  if (form?.start_date) {
    params = `${params}&start_date=${form?.start_date.format(dateFormat)}`;
  }
  // End date
  if (form?.end_date) {
    params = `${params}&end_date=${form?.end_date.format(dateFormat)}`;
  }
  setFilter(params);
};

export const ReportFilter = ({ filter }) => {
  const { Panel } = Collapse;
  const { contextData } = useAppContext();
  const { can_admin, app_can_admin } = contextData;
  const {
    setFilter,
    error,
    financial_years,
    loading,
    form,
    updateFormValue,
    updateFormValues,
    districts,
    divisions,
    parishes,
  } = filter || {};

  return (
    <Collapse
      defaultActiveKey={1}
      size="small"
      style={{ marginBottom: 10 }}
      expandIconPosition="end"
    >
      <Panel header="Data Filters" key={1}>
        <div className="flex wrap gap10">
          <RowGrid className="flexEnd gap10">
            <Wrapper className="dashboard-filter">
              <SelectInput
                disabled={!GetPermissions([CAN_ADMIN, CAN_DASHBOARD])}
                options={districts?.map((sup) => ({
                  label: sup.name,
                  value: sup.id,
                }))}
                id="municipality"
                name="municipality"
                label="City / Municipal / District"
                onChange={(value) => updateFormValue("municipality", value)}
                value={form?.municipality || ""}
                error={error}
              />
            </Wrapper>

            <Wrapper className="dashboard-filter">
              <SelectInput
                disabled={
                  !GetPermissions([
                    CAN_ADMIN,
                    CAN_DASHBOARD,
                    APP_CAN_APPROVE,
                    CAN_PHYSICAL_PLANNING,
                  ])
                }
                options={divisions?.map((sup) => ({
                  label: sup.name,
                  value: sup.id,
                }))}
                id="division"
                name="division"
                label="Division / Sub-County"
                onChange={(value) => updateFormValue("division", value)}
                value={form?.division || ""}
                maxMenuHeight={250}
                error={error}
              />
            </Wrapper>

            <Wrapper className="dashboard-filter">
              <SelectInput
                disabled={
                  !GetPermissions([
                    CAN_ADMIN,
                    CAN_DASHBOARD,
                    APP_CAN_APPROVE,
                    CAN_DIVISION,
                    CAN_PHYSICAL_PLANNING_DIVISION,
                    CAN_PHYSICAL_PLANNING
                  ])
                }
                options={parishes?.map((sup) => ({
                  label: sup.name,
                  value: sup.id,
                }))}
                id="parish"
                name="parish"
                label="Parish"
                onChange={(value) => updateFormValue("parish", value)}
                value={form?.parish || ""}
                maxMenuHeight={250}
                error={error}
              />
            </Wrapper>

            <Wrapper>
              <Label>Financial Year</Label>
              <Select
                allowClear
                style={{ width: "100%" }}
                value={form?.fin_year || ""}
                onChange={(value) =>
                  updateFormValue("fin_year", financial_years[value])
                }
                options={financial_years?.map((val, i) => ({
                  label: val,
                  value: i,
                }))}
              />
            </Wrapper>

            <Wrapper>
              <Label>Start Date</Label>
              <DatePicker
                value={setPickerDate(form?.start_date) || ""}
                style={{ width: "100%" }}
                onChange={(value) =>
                  updateFormValue("start_date", value?.format(dateFormat))
                }
              />
            </Wrapper>

            <Wrapper>
              <Label>End Date</Label>
              <DatePicker
                style={{ width: "100%" }}
                value={setPickerDate(form?.end_date) || ""}
                onChange={(value) =>
                  updateFormValue("end_date", value?.format(dateFormat))
                }
              />
            </Wrapper>
          </RowGrid>

          <Wrapper>
            <Button
              type="primary"
              block
              loading={loading}
              onClick={() => setFilter(form)}
            >
              Filter
            </Button>
          </Wrapper>
        </div>
      </Panel>
    </Collapse>
  );
};

const Label = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

const InputWrapper = styled.div`
  width: 31.8%;
  @media (max-width: 990px) {
    width: 100%;
  }
`;

const columnWidth = `calc(33.3% - 7px)`;

export const RowGrid = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: ${columnWidth} ${columnWidth} ${columnWidth};

  @media (max-width: 990px) {
    grid-template-columns: 48% 48%;
  }
`;
