import { LogoKcca, LogoKccaWhite } from "../../../assets/svg/SvgIcons";

export default function LogoComponent({
  height,
  nolabel = false,
  dark = false,
}) {
  return (
    <div className="font12 semiBold pointer flexNullCenter">
      <img
        src={dark ? LogoKccaWhite : LogoKcca}
        style={{
          height: height ? height : 10,
          marginBlock: 10,
          marginRight: 10,
        }}
      />
      <div
        style={{
          display: nolabel ? "none" : "block",
          color: dark && "white",
          lineHeight: 1.5,
        }}
      >
        e-Permit Management <br /> Information System
      </div>
    </div>
  );
}
