import { Menu } from "antd";
import SubMenu from "antd/es/menu/SubMenu";
import { reportsOptions, settings } from "../../../constants/settings";
import { RecursiveMenu } from "../../layouts/RecursiveMenu";
import {
  GetPermissions,
  level1Permissions,
} from "../../../utils/permissionsUtils";

// Options Menu for right side drawer - Mobile View
export const OptionsMenu = () => (
  <Menu mode="inline">
    <SubMenu title="Reports">{RecursiveMenu(reportsOptions())}</SubMenu>
    {GetPermissions(level1Permissions) && (
      <SubMenu title="Settings">{RecursiveMenu(settings())}</SubMenu>
    )}
  </Menu>
);
