import { useState } from "react";
import useAxios from "../axos/useAxios";
import { useNavigate } from "react-router-dom";

const useGenericSet = () => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState({});
  const { post } = useAxios();
  const navigate = useNavigate();

  const uploadData = (options) => {
    const { api, id, params, type, mappables, manyToMany, redirect } = options;
    const extras = {};

    // Files or nested data are handled here
    if (mappables) {
      Object.keys(mappables).forEach((key) => {
        mappables[key].forEach((object, idx) => {
          Object.keys(object).forEach((name) => {
            extras[`${key}[${idx}]${name}`] = object[name];
          });
        });
      });
    }

    // If id is passed get single instance, other fetch list
    setError(null);
    setSuccess(null);
    post({
      api: id ? `${api}${id}/` : api,
      loader: setSubmitting,
      errorHandler: setError,
      successHandler: redirect
        ? () => {
            setSuccess(true);
            navigate(redirect, {
              replace: true,
              state: { message: "Submitted Successfully" },
            });
          }
        : (res) => {
            setData(res);
            setSuccess(true);
          },
      methodName: id ? "patch" : "post",
      params: { ...params, ...extras },
      manyToMany,
      type,
    });
  };

  const updateError = (err) => {
    setError(err ? { ...error, ...err } : null);
  };

  return {
    submitting,
    error,
    data,
    success,
    uploadData,
    updateError,
    setError,
    setSuccess,
    setSubmitting,
  };
};

export default useGenericSet;
