import React from "react";
import { InfoModal } from "../../../dialog/InfoModal";
import { CheckCircle } from "@mui/icons-material";
import { stringify } from "../../../../utils/helperUtil";

export const RequirementsList = (permitType) => {
  const items = permitRequirements[permitType] || [];

  return (
    <div className="flexColumn gap10">
      <p>
        These are the requirements for{" "}
        <span className="semiBold text-capitalize">
          {stringify(permitType)}
        </span>
        . Ensure that you have all the requirements before you get started.
      </p>
      {/* LIST */}
      {items?.map((item) => (
        <div className="flexNullCenter gap10">
          <CheckCircle fontSize="small" color="success" />
          <div>{item}</div>
        </div>
      ))}
    </div>
  );
};

export const RequirementsModal = () => {
  const pathname = window.location.pathname;
  const permitType = pathname.split("/")[1];

  return (
    <InfoModal
      label="View Requirements"
      buttonProps={{ style: { marginLeft: 10 } }}
      showOnLoad
      title="List of Requirements"
      children={RequirementsList(permitType)}
    />
  );
};

const permitRequirements = {
  "development-permit": [
    "Architectural Drawings",
    "Development Concept",
    "Search Letter",
    "Survey Report",
    "Proof of Land Ownership",
  ],
  "telecommunication-mast-permit": [
    "Architectural Drawings",
    "Structural Drawings",
    "Structural Integrity Report",
    "Clearance from UCC",
    "NEMA Clearance",
    "Search statement",
    "Survey Report",
    "Proof of Land Ownership",
  ],
  "outdoor-advertisement-permit": [
    "Concept (Artistic Impression)",
    "Consent to Install signage",
  ],
  "subdivision-consolidation-permits": [
    "Proof of Land Ownership",
    "Index Diagram",
    "Mutation Form",
    "Area Computation",
    "Planning Scheme",
    "Survey Report",
  ],
  "lease-application": [
    "Proof of Land Ownership",
    "Search Letter",
    "Copy of Approved Plans",
    "Proof of Payment of Land Premium and Ground Rent",
    "Allocation Letter",
  ],
  "change-of-land-use-permit": [
    "Proof of Land Ownership",
    "Search Letter",
    "Copy of Approved Plans",
    "Proof of Payment of Land Premium and Ground Rent",
    "Allocation Letter",
  ],
  "condominium-permit": [
    "Proof of Land Ownership",
    "Search Letter",
    "Survey Report",
    "Approved Architectural Drawings",
    "Condominium Plans",
  ],
  "chainlink-permit": [
    "Proof of Land Ownership",
    "Search Letter",
    "Survey Report",
  ],
};
