import React, { useEffect, useState } from "react";
import FormInput from "../../../common/FormInput";
import useForm from "../../../../hooks/common/useForm";
import SelectInput from "../../../common/SelectInput";
import { API_GENERATE_BILL, CONDOMINIUM_PERMIT_API } from "../../../../constants/api";
import useGenericSet from "../../../../hooks/common/useGenericSet";

const CondominiumPermitAssessment = ({tax, tax_id, formId, proceed, period }) => {
  const { form, updateFormValue, updateFormValues, onChange } = useForm();
  const { uploadData, error, success } = useGenericSet();

  const options = [
    {
      id: "0",
      name: "No",
    },
    {
      id: "1",
      name: "Yes",
    },
  ];


  useEffect(() => {
     updateFormValues({
        sub_total: parseFloat(form?.permit_fee || 0) + parseFloat(form?.other_fee || 0),
        vat: 0.18*(parseFloat(form?.permit_fee || 0) + parseFloat(form?.other_fee || 0)),
        total_amount: (parseFloat(form?.permit_fee || 0) + parseFloat(form?.other_fee || 0)) + 0.18*(parseFloat(form?.permit_fee || 0) + parseFloat(form?.other_fee || 0)) 
     })
  }, [form?.permit_fee, form?.other_fee])


  useEffect(() => {
    if (success) {
      window.location.reload(true)
    }
  }, [success])

  const onSubmit = (event) => {
    event.preventDefault();

    uploadData({
      api: API_GENERATE_BILL,
      params : {
        tax: "condominium_permit",
        period: period,
        tax_id: tax_id,
        other_fees_select: 0,
        other_fees: Math.round(form.other_fee) || 0,
        other_fees_spec: form.specify_fee || "null",
        permit_fee: Math.round(form.permit_fee),
        amount: Math.round(form?.total_amount)
      }
    })
  };

//   const onSubmit = (event) => {
//     event.preventDefault();
//     proceed({ amount: form?.total_amount });

//     uploadData({
//         api: CONDOMINIUM_PERMIT_API,
//         id: tax_id,
//         params: {
//           condominium_permit_fee: form?.permit_fee,
//           other_fees_spec: form.specify_fee,
//           other_fees: form.other_fee,
//           amount: form?.total_amount
//         },
//       });
//   };

  return (
    <form id={formId} onSubmit={onSubmit}>
      <div className="panel panel-primary">
        <div className="panel-heading">
          <div className="panel-title">Generate Bill for Condominium Permit</div>
        </div>

        <div className="panel-body">
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <FormInput
                            label="Condominium Permit Fee"
                            type="number"
                            name="permit_fee"
                            value={form?.permit_fee}
                            onChange={onChange}
                            required
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                       <SelectInput
                            options={options?.map(
                            (sup) => ({
                                label: sup.name,
                                value: sup.id,
                            })
                            )}
                            name="other_fees"
                            label="Any Other Fees?"
                            onChange={(value) =>
                            updateFormValue("other_fees", value)
                            }
                            value={form?.other_fees || ""}
                            required
                        />
                    </div>
                </div>
            </div>

            { form?.other_fees === "1"?
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <FormInput
                            label="Specify"
                            type="text"
                            name="specify_fee"
                            value={form?.specify_fee}
                            onChange={onChange}
                            required
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <FormInput
                            label="Other Fee"
                            type="number"
                            name="other_fee"
                            value={form?.other_fee}
                            onChange={onChange}
                            required
                        />
                    </div>
                </div>
            </div>
            : null }

            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <FormInput
                            label="Sub total"
                            type="number"
                            name="sub_total"
                            value={form?.sub_total}
                            onChange={onChange}
                            required
                            disabled
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <FormInput
                            label="VAT (18%)"
                            type="number"
                            name="vat"
                            value={form?.vat}
                            onChange={onChange}
                            required
                            disabled
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="form-group">
                        <FormInput
                            label="Total Amount"
                            type="text"
                            name="total_amount"
                            value={Math.round(form?.total_amount)}
                            onChange={onChange}
                            required
                            disabled
                        />
                    </div>
                </div>
            </div>
        </div>
      </div>
    </form>
  );
};

CondominiumPermitAssessment.propTypes = {
};

export default CondominiumPermitAssessment;
