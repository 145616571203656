import React from "react";

import { Layout, Menu, theme } from "antd";
import { RequirementsList } from "../developement-control/info-modal/RequirementsModal";
import { Link, useParams } from "react-router-dom";
import { navLinks } from "../../nav-bar/landing/NavLinks";
import { stringify } from "../../../utils/helperUtil";
import LogoComponent from "../../common/logo/LogoComponent";

const { Header, Content, Sider } = Layout;
const { Item } = Menu;

const navItems = [
  {
    label: "Home",
    to: "/",
  },
  {
    label: "Permits",
    key: "permits",
  },
];

const permitItems = navLinks?.[1]?.children;

export const Permits = () => {
  const { id: permitType } = useParams();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Layout style={{ height: "100vh" }}>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <LogoComponent dark height={45} />

        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["permits"]}
          style={{
            flex: 1,
            marginLeft: 30,
            minWidth: 0,
          }}
        >
          {navItems.map(({ label, to, key }, i) => (
            <Item key={key || i}>
              <Link to={to}>{label}</Link>
            </Item>
          ))}
        </Menu>
      </Header>
      <Content>
        <Layout
          style={{
            padding: "24px 0",
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            height: "100%",
          }}
        >
          <Sider
            style={{
              background: colorBgContainer,
            }}
            width={250}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={permitType}
              style={{
                height: "100%",
              }}
            >
              {permitItems.map(({ label, route }, i) => (
                <Item key={route?.split("/")[2]}>
                  <Link to={route} className="font13">
                    {label}
                  </Link>
                </Item>
              ))}
            </Menu>
          </Sider>
          <Content
            style={{
              padding: "0 24px",
            }}
          >
            <h2 style={{ marginBottom: 10 }} className="text-capitalize">
              {stringify(permitType)}
            </h2>
            <div style={{ borderBottom: "1px solid #ddd", marginBottom: 20 }} />
            {RequirementsList(permitType)}
          </Content>
        </Layout>
      </Content>
    </Layout>
  );
};
