import styled from "styled-components";
import { configColor } from "../styled/Theme";
import { alpha } from "@mui/material";
import { green } from "@mui/material/colors";

const Wrapper = styled.section`
  width: 100%;
`;

const Header = styled.div`
  padding-bottom: 50px;
  @media (max-width: 960px) {
    width: 100%;
    text-align: center;
  }
`;

const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;

const MapWrapper = styled.div`
  width: auto;
  height: 88%;
  border: 1px solid #d0d0d0;
  border-radius: 10px;
  overflow: hidden;
  @media (max-width: 960px) {
    height: 350px;
  }
`;

const RoundedContainer = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-radius: 30px;
`;

const Divider = styled.div`
  height: 1px;
  margin: 10px 0;
  background-color: #0000002a;
`;

export const LeftMenuButton = styled.button`
  padding: 10px 0 10px 15px;
  background: none;
  border: none;
`;

export const TagSpan = ({
  children,
  dark = false,
  light = false,
  error = false,
  primary = false,
  onClick,
}) => {
  let color = green[600];
  if (dark) color = "#fff";
  if (light) color = "#000";
  if (error) color = "#ff0000";
  if (primary) color = configColor;

  const styles = {
    color: `${color}`,
    paddingTop: "2px",
    fontSize: "12px",
    fontWeight: 600,
    whiteSpace: "nowrap",
    textAlign: "center",
    backgroundColor: `${alpha(color, 0.15)}`,
    border: `1px solid ${alpha(color, 0.4)}`,
    borderRadius: "15px",
    paddingInline: "15px",
    cursor: `${onClick && "pointer"}`,
  };
  return (
    <span onClick={onClick} style={styles}>
      {children}
    </span>
  );
};

export const RowWrapper = styled.section`
  width: 100%;
  @media (max-width: 990px) {
    flex-direction: column;
  }
`;

export { Wrapper, Header, BtnWrapper, MapWrapper, RoundedContainer, Divider };
