import {
  sAmendment,
  sDevelopmentControl,
  sMarketDues,
  sAuditAuthority,
  sONTR,
  sPayments,
  sPropertyValuation,
  sRevenueSources,
  sTenderedRevenue,
} from "../components/layouts/LeftMenu";
import {
  APPLICATIONS_UNDER_SCRUTINY,
  APPROVED_STATUS,
  AUDIT_AUTHORITY,
  DATA_ENTRANT,
  DEFERRED_STATUS,
  DIVISION_ADMIN,
  FRONT_DESK_OFFICER,
  INSPECTED_STATUS,
  LAND_SURVEYOR,
  MARKET_ADMIN,
  PARISH_ADMIN,
  PENDING_ASSESSMENT_STATUS,
  PENDING_DECISION_STATUS,
  PHYSICAL_PLANNER,
  PROFESSIONAL,
  PUBLIC_APP_USER,
  RECOMMENDED_STATUS,
  REJECTED_STATUS,
  REVIEWED_STATUS,
  SECRETARY_PHYSICAL_PLANNING_COMMITTEE,
  SENT_BACK_STATUS,
  STATUS_PENDING,
  SUPERVISIOR_DEV_CONTROL,
  SUPPER_USER,
  UNDER_INSPECTION_STATUS,
  UNSCHEDULED_STATUS,
  VALUATION_APPROVER,
  VALUATION_DATA_ENTRANT,
  VERIFICATION_AUTHORITY,
} from "../constants/webapp";
import { useAppContext } from "../context/AppContext";

import { isEmpty } from "../context/authContext";

export const isSuperUser = (user) => {
  return user.type === SUPPER_USER;
};

export const isSystemUserAdmin = (user) => {
  return isSuperUser(user);
};

export const GetPermissions = (key, path) => {
  let visible = false;
  const { contextData } = useAppContext() || {};
  const { role_details, actor_details } = contextData;
  const roleName = role_details?.name;
  function checkForPermit(permit) {
    permit = permit.replaceAll(" ", "_").toLowerCase();
    // returns true if permit is found
    return actor_details?.hasOwnProperty(permit);
  }

  function checkForPermitLevel(level, permit) {
    permit = permit.replaceAll(" ", "_").toLowerCase();

    // check
    if (
      level === APPLICATIONS_UNDER_SCRUTINY &&
      actor_details &&
      actor_details[permit]?.some((item) =>
        [
          INSPECTED_STATUS,
          REVIEWED_STATUS,
          UNDER_INSPECTION_STATUS,
          UNSCHEDULED_STATUS,
          RECOMMENDED_STATUS,
        ]?.includes(item.toLowerCase())
      )
    )
      return true;

    // returns true if permit is found
    return (
      actor_details && actor_details[permit]?.includes(level.toUpperCase())
    );
  }

  // ADMIN USER
  if (role_details?.can_admin === 1) {
    visible = true;
  } else if (
    // APPROVING AUTHORITY
    role_details?.can_municipality === 1
  ) {
    visible = true;
    // remove roles
    switch (key) {
      case "pendingPV":
      case "approvedPV":
      case "defferedPV":
      case "newPV":
      case sDevelopmentControl:
      case sPropertyValuation:
        visible = false;
        break;

      default:
        break;
    }
  }

  // control roles
  switch (roleName) {
    // physical planner
    // case PHYSICAL_PLANNER:
    // case FRONT_DESK_OFFICER:
    //   switch (key) {
    //     case sDevelopmentControl:
    //       visible = true;
    //       break;

    //     default:
    //       break;
    //   }
    //   break;

    case PROFESSIONAL:
      switch (key) {
        case sDevelopmentControl:
          visible = true;
          break;
        default:
          break;
      }
      break;

    case PARISH_ADMIN:
      switch (key) {
        case sMarketDues:
          visible = true;
          break;
        default:
          break;
      }
      break;

    case DIVISION_ADMIN:
      switch (key) {
        case sMarketDues:
          visible = true;
          break;
        default:
          break;
      }
      break;

    case VERIFICATION_AUTHORITY:
      switch (key) {
        case sMarketDues:
          visible = true;
          break;
        default:
          break;
      }
      break;

    // market admin
    case MARKET_ADMIN:
      switch (key) {
        case sMarketDues:
          visible = true;
          break;
        default:
          break;
      }
      break;

    case AUDIT_AUTHORITY:
      switch (key) {
        case sAuditAuthority:
          visible = true;
          break;
        default:
          break;
      }
      break;

    // data entrant
    case DATA_ENTRANT:
      switch (key) {
        case "enquiries":
          visible = true;
          break;
        default:
          break;
      }
      break;
    //

    // Valuation Approver
    case VALUATION_APPROVER:
    case VALUATION_DATA_ENTRANT:
      switch (key) {
        case sPropertyValuation:
        case "newPV":
        case "pendingPV":
        case "approvedPV":
        case "defferedPV":
        case "pvAction":
          visible = true;
          break;
        default:
          break;
      }
      break;

    default:
      break;
  }

  // development control permits
  switch (key) {
    case "Routine Inspection":
    case "Development Permit":
    case "Occupation Permit":
    case "Enforcement":
    case "Building Operation":
    case "Outdoor Advertisement Permit":
    case "Subdivision Consolidation Permit":
    case "Lease Application":
    case "Condominium Permit":
    case "Demolition Permit":
    case "Renovation Permit":
    case "Excavation Permit":
    case "Hoarding Permit":
    case "Chainlink Permit":
      visible = checkForPermit(key) || false;
      break;

    default:
      break;
  }

  // development control permits
  switch (key) {
    case INSPECTED_STATUS:
    case REVIEWED_STATUS:
    case RECOMMENDED_STATUS:
    case UNDER_INSPECTION_STATUS:
    case UNSCHEDULED_STATUS:
    case REJECTED_STATUS:
    case DEFERRED_STATUS:
    case APPROVED_STATUS:
    case PENDING_DECISION_STATUS:
    case PENDING_ASSESSMENT_STATUS:
    case SENT_BACK_STATUS:
    case STATUS_PENDING:
    case APPLICATIONS_UNDER_SCRUTINY:
      visible = checkForPermitLevel(key, path) || false;
      break;

    default:
      break;
  }

  // PUBLIC USER
  if (role_details?.can_public === 1 && roleName === PUBLIC_APP_USER) {
    switch (key) {
      case sRevenueSources:
      case sDevelopmentControl:
      case sAmendment:
      case sPayments:
      case sTenderedRevenue:
      case sONTR:
      case sPropertyValuation:
      case "Business Deactivation":
      case "Tax Payers":
      case "TIN Registration":
      case "Development Permit": // development control roles
      case "Occupation Permit":
      //case "Enforcements":
      case "Building Operation":
      case "Outdoor Advertisement Permit":
      case "Subdivision Consolidation Permit":
      case "Lease Application":
      case "Condomimium Permit":
      case "Demolition Permit":
      case "Renovation Permit":
      case "Excavation Permit":
      case "Hoarding Permit":
      case "Chainlink Permit":
      case INSPECTED_STATUS:
      case REVIEWED_STATUS:
      case RECOMMENDED_STATUS:
      case UNDER_INSPECTION_STATUS:
      case UNSCHEDULED_STATUS:
      case REJECTED_STATUS:
      case DEFERRED_STATUS:
      case APPROVED_STATUS:
      case PENDING_DECISION_STATUS:
      case PENDING_ASSESSMENT_STATUS:
      case SENT_BACK_STATUS:
      case STATUS_PENDING:
      case APPLICATIONS_UNDER_SCRUTINY:
        visible = true;
        break;

      default:
        break;
    }
  }

  return visible;
};

export const IsMarketAdmin = () => {
  const { contextData } = useAppContext() || {};
  const { role_details } = contextData;
  const roleName = role_details?.name;

  return roleName === MARKET_ADMIN;
};

export const HasPermit = () => {
  const { contextData } = useAppContext() || {};
  const { actor_details } = contextData;
  // if actor_details is not empty
  return actor_details && !isEmpty(actor_details);
};

export const IsAdmin = () => {
  const { contextData } = useAppContext() || {};
  const { role_details } = contextData;
  return role_details?.can_admin;
};

export const IsAuditAuthority = () => {
  const { contextData } = useAppContext() || {};
  const { role_details } = contextData;
  const roleName = role_details?.name;
  return roleName === AUDIT_AUTHORITY;
};

export const HasUserType = (userArray) => {
  const { contextData } = useAppContext() || {};
  const { role_details } = contextData;
  const roleName = role_details?.name;
  return userArray.includes(roleName);
};

export const developmentControlRoles = [
  FRONT_DESK_OFFICER,
  SECRETARY_PHYSICAL_PLANNING_COMMITTEE,
  SUPERVISIOR_DEV_CONTROL,
  LAND_SURVEYOR,
  PHYSICAL_PLANNER,
  "Supervisor Building Control",
  "Senior Environment Officer",
  "Public Health Officer",
  "Drainage Engineer",
  "Traffic Engineer",
  "Structural Engineer",
  "Architect",
  "Mechanical Engineer",
  "Electrical Engineer",
  "Manager Buildings",
  "Building Committee",
  "Chairperson Building Committee",
  "Manager Physical Planning",
  "Physical Planning Committee",
  "Officer Legal",
  "Director in Charge Buildings",
  "Building Inspector",
  "Officer Outdoor",
  "Officer Landscape",
  "Building Control Officer",
  "Physical Planning Authority",
  "Environmental Officer",
  "Director in Charge of Physical Planning",
  "Front Desk Officer (TC)",
  "Physical Planner (TC)",
  "Supervisor Development Control (TC)",
  "Supervisor Building Control (TC)",
  "Senior Environment Officer (TC)",
  "Environmental Officer (TC)",
  "Public Health Officer (TC)",
  "Land Surveyor (TC)",
  "Structural Engineer (TC)",
  "Building Committee (TC)",
  "Chairperson Building Committee (TC)",
  "Physical Planning Committee (TC)",
  "Secretary Physical Planning Committee (TC)",
  "Officer Legal (TC)",
  "Director in Charge Buildings (TC)",
  "Director in Charge of Physical Planning (TC)",
  "Officer Outdoor (TC)",
];
