import styled from "styled-components";

import { Logo } from "../../../assets/svg/SvgIcons";

export const GenericLoader = () => {
  return (
    <LoaderWrapper className="flexCenter flexColumn">
      <div className="loader" />
    </LoaderWrapper>
  );
};

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  align-self: center;
  min-height: 50vh;
  @media (min-width: 1024px) {
    max-width: calc(100vw - 240px);
  }
`;
