import { SettingOutlined } from "@ant-design/icons";
import {
  Add,
  AddCard,
  Description,
  Payment,
  RoomService,
  Sell,
  SupervisorAccount,
  VerifiedUserSharp,
  WorkspacePremium,
  WorkHistory,
  Hotel,
  Store,
  Storefront,
  AssignmentTurnedIn,
  Money,
  HomeRounded,
  Traffic,
  LocationCity,
  List,
  Edit,
  ContentPasteGo,
  ContactSupport,
  DataObject,
  KeyOutlined,
  QuestionMark,
  LocationOn,
  DesktopAccessDisabled,
  ThumbDown,
  Gavel,
  History,
} from "@mui/icons-material";
import { Menu } from "antd";
import { RecursiveMenu } from "./RecursiveMenu";
import {
  APPROVED_STATUS,
  INSPECTED_STATUS,
  PAID,
  PENDING_ASSESSMENT_STATUS,
  PENDING_DECISION_STATUS,
  PENDING_STATUS,
  RECOMMENDED_STATUS,
  REJECTED_STATUS,
  REVIEWED_STATUS,
  SENT_BACK_STATUS,
  STATUS_ASSESSED,
  STATUS_DEFFERED,
  STATUS_REJECTED,
  STATUS_SUBMITTED,
  TENDERER_STATUS_DEFERRED,
  TENDERER_STATUS_REJECTED,
  TENDER_STATUS_APPROVED,
  TENDER_STATUS_PENDING,
  UNDER_INSPECTION_STATUS,
  UNSCHEDULED_STATUS,
  DEFERRED_STATUS,
  STATUS_DEVELOPMENT_CONTROL,
  STATUS_REVENUE_SOURCE,
  STATUS_SERVICE_APPLICATION,
  STATUS_GRIEVANCE,
  PROPERTY_VALUATION_STATUS_APPROVED,
  STATUS_AWAIT_COURT_HEARING,
  STATUS_APPROVED_OBJECTIONS,
  STATUS_WITHDRAWN_OBJECTIONS,
  STATUS_REJECTED_OBJECTIONS,
  STATUS_DEACTIVATED,
  PROPERTY_VALUATION_STATUS_DRAFTED,
  PROPERTY_VALUATION_STATUS_CERTIFIED,
  CAN_ADMIN,
  CAN_MUNICIPALITY,
  CAN_PARISH,
  APP_CAN_APPROVE,
  CAN_PUBLIC,
  APPLICATIONS_UNDER_SCRUTINY,
  PROPERTY_VALUATION_STATUS_PENDING,
  AUDIT_AUTHORITY,
  CAN_PROFESSIONAL,
  MIS_AUTHORITY,
  ONTR_ACTIVE_STATUS,
  PUBLIC_APP_USER,
  CAN_PHYSICAL_PLANNING,
  CAN_PHYSICAL_PLANNING_DIVISION,
  OLD_VALUATION_ROLL,
} from "../../constants/webapp";
import {
  GetPermissions as GetPermissionsRoles,
  HasPermit,
  IsMarketAdmin,
  IsAuditAuthority,
  HasUserType,
  IsAdmin,
} from "../../utils/authUtils";
import {
  FaAdversal,
  FaBan,
  FaCashRegister,
  FaCheck,
  FaFileAlt,
  FaTruckMoving,
  FaWrench,
} from "react-icons/fa";
import { configColor } from "../styled/Theme";
import { useModals } from "../../hooks/modals/useModals";
import React from "react";
import { DeactivateButton } from "../common/Button";
import {
  developmentControlPermissions,
  GetPermissions,
  mainPermissions,
} from "../../utils/permissionsUtils";
import { posUserGuide, userGuideInternal } from "../../constants/links";
import { useAppContext } from "../../context/AppContext";

/* REUSABLE STRINGS */
// misc
export const sRegister = "Register";
export const sNew = "New";

// statuses
export const sPendingRegister = `Pending ${sRegister}`;
export const sPendingRequestRegister = `Requests ${sRegister}`;
export const sApprovedRegister = `Approved ${sRegister}`;
export const sDraftRegister = `Draft Valuation ${sRegister}`;
export const sCertifiedRegister = `Certified Valuation ${sRegister}`;
export const sRejecetedRegister = `Rejected ${sRegister}`;
export const sDefferedRegister = `Deffered ${sRegister}`;
export const sRejectedRegister = `Rejected ${sRegister}`;
export const sPropertyRegister = `Property ${sRegister}`;
export const sTenderer = `Tenderer ${sRegister}`;
export const sDeactivatedRegister = `Deactivated ${sRegister}`;

// revenue sources
export const sTradingLicence = "Trade License";
export const sRequest = "Request";
export const sRentandRates = "Rent and Rates";
export const sLHT = "Local Hotel Tax";
export const sLST = "Local Service Tax";
export const sOperationalPermit = "Operational Permit";
export const sOutdoorAdvertising = "Outdoor Advertising";
export const sMarketRent = "Market Rent";
export const sPropertyTax = "Property Tax";
export const sTransport = "Transport";
export const sRentRates = "Rent & Rates";
export const sGroundRent = "Ground Rent";
export const sMarketDues = "Market Dues";
export const sAuditAuthority = AUDIT_AUTHORITY;
export const sPropertyValuation = "Property Valuation";
export const sParkUser = "Park User Fees";

// strings
export const sRevenueSources = "Revenue Sources";
export const sDevelopmentControl = "Development and Building Control";
export const sAmendment = "Amendment";
export const sPayments = "Payments";
export const sTenderedRevenue = "Tendered Revenue";
export const sONTR = "ONTR";

// development control strings
export const sDevelopmentPermit = "Development Permit";
export const sOccupationPermit = "Occupation Permit";
export const sBuildingOperation = "Building Operation";
export const sOutdoorAdvertisementPermit = "Outdoor Advertisement Permit";
export const sSubdivisionConsolidationPermit =
  "Subdivision Consolidation Permit";
export const sLeaseApplication = "Lease Application";
export const sCondominiumPermit = "Condominium Permit";
export const sDemolitionPermit = "Demolition Permit";
export const sRenovationPermit = "Renovation Permit";
export const sExcavationPermit = "Excavation Permit";
export const sHoardingPermit = "Hoarding Permit";
export const sChainlinkPermit = "Chainlink Permit";

// Location Details
export const sMunicipality = "Municipality/City/District";
export const sDivision = "Division/Town Council/Sub County";
export const sParish = "Parish/Ward";
export const sVillage = "Village/Cell/Zone";
export const sStreet = "Street";
export const sTaxpayerName = "Taxpayer name";
export const sheightFromGround = "Total Height from Ground Level";
export const sLandPremium = "Land Premium";
export const sNIN = "National Identification Number (NIN)";

/* -------------------- */
const LeftMenuItems = () => {
  const { setOpenPrn, setOpenPermit } = useModals();
  const { contextData } = useAppContext();
  const { actor_details } = contextData;
  let pending_decision = false;

  for (var key in actor_details) {
    if (actor_details[key].includes("PENDING DECISION"))
      pending_decision = true;
    break;
  }

  const menu = [
    {
      title: "Home",
      Icon: <HomeRounded />,
      link: "/",
      visible: true,
      key: "home",
    },

    // Revenue Sources
    {
      title: sRevenueSources,
      visible: false,
      // GetPermissions([...mainPermissions, CAN_PUBLIC]) ||
      // IsMarketAdmin() ||
      // IsAuditAuthority(),
      Icon: <VerifiedUserSharp />,
      children: [
        // Trading Licence
        {
          title: sTradingLicence,
          Icon: <Sell />,
          visible: !IsMarketAdmin(),
          children: [
            {
              title: `${sNew} ${sTradingLicence}`,
              link: "/trading-license/create",
              visible: !IsAuditAuthority(),
              Icon: <Add />,
            },
            {
              title: sPendingRegister,
              link: `/trading-license/${STATUS_SUBMITTED}`,
              Icon: <Description />,
            },
            {
              title: sApprovedRegister,
              link: `/trading-license/${STATUS_ASSESSED}`,
              Icon: <Description />,
            },
            {
              title: sRejecetedRegister,
              link: `/trading-license/${STATUS_REJECTED}`,
              Icon: <Description />,
            },
            {
              title: sDefferedRegister,
              link: `/trading-license/${STATUS_DEFFERED}`,
              Icon: <Description />,
            },
            {
              title: sDeactivatedRegister,
              link: `/trading-license/${STATUS_DEACTIVATED}`,
              Icon: <DeactivateButton />,
            },
          ],
        },

        // Rent and Rates
        {
          title: sRentandRates,
          Icon: <Money />,
          visible: !IsMarketAdmin(),
          children: [
            {
              title: `${sNew} ${sRentandRates}`,
              link: "/rent-and-rates/create",
              visible: !IsAuditAuthority(),
              Icon: <Add />,
            },
            {
              title: sPendingRegister,
              link: `/rent-and-rates/${STATUS_SUBMITTED}`,
              Icon: <Description />,
            },
            {
              title: sApprovedRegister,
              link: `/rent-and-rates/${STATUS_ASSESSED}`,
              Icon: <Description />,
            },
            {
              title: sRejecetedRegister,
              link: `/rent-and-rates/${STATUS_REJECTED}`,
              Icon: <Description />,
            },
            {
              title: sDefferedRegister,
              link: `/rent-and-rates/${STATUS_DEFFERED}`,
              Icon: <Description />,
            },
            {
              title: sDeactivatedRegister,
              link: `/rent-and-rates/${STATUS_DEACTIVATED}`,
              Icon: <DeactivateButton />,
            },
          ],
        },

        // Local Hotel Tax
        {
          title: sLHT,
          Icon: <Hotel />,
          visible: !IsMarketAdmin(),
          children: [
            {
              title: "New Local Hotel Tax",
              link: "/local-hotel-tax/create",
              visible: !IsAuditAuthority(),
              Icon: <Add />,
            },
            {
              title: sPendingRegister,
              link: `/local-hotel-tax/${STATUS_SUBMITTED}`,
              Icon: <Description />,
            },
            {
              title: sApprovedRegister,
              link: `/local-hotel-tax/${STATUS_ASSESSED}`,
              Icon: <Description />,
            },
            {
              title: sRejecetedRegister,
              link: `/local-hotel-tax/${STATUS_REJECTED}`,
              Icon: <Description />,
            },
            {
              title: sDefferedRegister,
              link: `/local-hotel-tax/${STATUS_DEFFERED}`,
              Icon: <Description />,
            },
            {
              title: sDeactivatedRegister,
              link: `/local-hotel-tax/${STATUS_DEACTIVATED}`,
              Icon: <DeactivateButton />,
            },
          ],
        },
        // Local Service Tax
        {
          title: sLST,
          Icon: <RoomService />,
          visible: !IsMarketAdmin(),
          children: [
            {
              title: `${sNew} ${sLST}`,
              link: "/local-service-tax/create",
              visible: !IsAuditAuthority(),
              Icon: <Add />,
            },
            {
              title: sPendingRegister,
              link: `/local-service-tax/${STATUS_SUBMITTED}`,
              Icon: <Description />,
            },
            {
              title: sApprovedRegister,
              link: `/local-service-tax/${STATUS_ASSESSED}`,
              Icon: <Description />,
            },
            {
              title: sRejecetedRegister,
              link: `/local-service-tax/${STATUS_REJECTED}`,
              Icon: <Description />,
            },
            {
              title: sDefferedRegister,
              link: `/local-service-tax/${STATUS_DEFFERED}`,
              Icon: <Description />,
            },
            {
              title: sDeactivatedRegister,
              link: `/local-service-tax/${STATUS_DEACTIVATED}`,
              Icon: <DeactivateButton />,
            },
          ],
        },
        /* Operational Permit */
        {
          title: sOperationalPermit,
          Icon: <WorkspacePremium />,
          visible: !IsMarketAdmin(),
          children: [
            {
              title: `${sNew} ${sOperationalPermit}`,
              link: "/operational-permit/create",
              visible: !IsAuditAuthority(),
              Icon: <Add />,
            },
            {
              title: sPendingRegister,
              link: `/operational-permit/${STATUS_SUBMITTED}`,
              Icon: <Description />,
            },
            {
              title: sApprovedRegister,
              link: `/operational-permit/${STATUS_ASSESSED}`,
              Icon: <Description />,
            },
            {
              title: sRejecetedRegister,
              link: `/operational-permit/${STATUS_REJECTED}`,
              Icon: <Description />,
            },
            {
              title: sDefferedRegister,
              link: `/operational-permit/${STATUS_DEFFERED}`,
              Icon: <Description />,
            },
            {
              title: sDeactivatedRegister,
              link: `/operational-permit/${STATUS_DEACTIVATED}`,
              Icon: <DeactivateButton />,
            },
          ],
        },

        /* Outdoor Advertising */
        {
          title: sOutdoorAdvertising,
          Icon: <FaAdversal />,
          visible: !IsMarketAdmin(),
          children: [
            {
              title: `${sNew} ${sOutdoorAdvertising}`,
              link: "/outdoor-advertising/create",
              visible: !IsAuditAuthority(),
              Icon: <Add />,
            },
            {
              title: sPendingRegister,
              link: `/outdoor-advertising/${STATUS_SUBMITTED}`,
              Icon: <Description />,
            },
            {
              title: sApprovedRegister,
              link: `/outdoor-advertising/${STATUS_ASSESSED}`,
              Icon: <Description />,
            },
            {
              title: sRejecetedRegister,
              link: `/outdoor-advertising/${STATUS_REJECTED}`,
              Icon: <Description />,
            },
            {
              title: sDefferedRegister,
              link: `/outdoor-advertising/${STATUS_DEFFERED}`,
              Icon: <Description />,
            },
            {
              title: sDeactivatedRegister,
              link: `/outdoor-advertising/${STATUS_DEACTIVATED}`,
              Icon: <DeactivateButton />,
            },
          ],
        },

        // Ground Rent
        {
          title: sGroundRent,
          Icon: <Money />,
          visible: !IsMarketAdmin(),
          children: [
            {
              title: `${sNew} ${sGroundRent}`,
              link: "/ground-rent/create",
              visible: !IsAuditAuthority(),
              Icon: <Add />,
            },
            {
              title: sPendingRegister,
              link: `/ground-rent/${STATUS_SUBMITTED}`,
              Icon: <Description />,
            },
            {
              title: sApprovedRegister,
              link: `/ground-rent/${STATUS_ASSESSED}`,
              Icon: <Description />,
            },
            {
              title: sRejecetedRegister,
              link: `/ground-rent/${STATUS_REJECTED}`,
              Icon: <Description />,
            },
            {
              title: sDefferedRegister,
              link: `/ground-rent/${STATUS_DEFFERED}`,
              Icon: <Description />,
            },
            {
              title: sDeactivatedRegister,
              link: `/ground-rent/${STATUS_DEACTIVATED}`,
              Icon: <DeactivateButton />,
            },
          ],
        },

        /*  Market Rent */
        {
          title: sMarketRent,
          Icon: <Store />,
          // visible: !IsMarketAdmin(),
          children: [
            {
              title: `${sNew} ${sMarketRent}`,
              link: "/market-rents/create",
              visible: !IsAuditAuthority(),
              Icon: <Add />,
            },
            {
              title: sPendingRegister,
              link: `/market-rents/${STATUS_SUBMITTED}`,
              Icon: <Description />,
            },
            {
              title: sApprovedRegister,
              link: `/market-rents/${STATUS_ASSESSED}`,
              Icon: <Description />,
            },
            {
              title: sRejecetedRegister,
              link: `/market-rents/${STATUS_REJECTED}`,
              Icon: <Description />,
            },
            {
              title: sDefferedRegister,
              link: `/market-rents/${STATUS_DEFFERED}`,
              Icon: <Description />,
            },
            {
              title: sDeactivatedRegister,
              link: `/market-rents/${STATUS_DEACTIVATED}`,
              Icon: <DeactivateButton />,
            },
          ],
        },
        /*  Market Dues */
        {
          title: sMarketDues,
          visible: GetPermissionsRoles(sMarketDues),
          Icon: <Storefront />,
          children: [
            {
              title: `${sNew} ${sMarketDues}`,
              link: "/market-dues/create",
              visible: IsMarketAdmin() || !IsAuditAuthority(),
              Icon: <Add />,
            },
            {
              title: "Market Dues Register",
              link: "/market-dues",
              Icon: <Description />,
            },
          ],
        },
        //Commercial Road User
        {
          title: sParkUser,
          Icon: <Traffic />,
          visible: GetPermissions([CAN_ADMIN]) && !IsMarketAdmin(),
          children: [
            {
              title: `${sNew} ${sParkUser}`,
              link: "/park-user-fees/create",
              visible: !IsAuditAuthority(),
              Icon: <Add />,
            },
            {
              title: "Pending Register",
              link: `/park-user-fees/${STATUS_SUBMITTED}`,
              Icon: <Description />,
            },
            {
              title: "Approved Register",
              link: `/park-user-fees/${STATUS_ASSESSED}`,
              Icon: <Description />,
            },
            {
              title: "Rejected Register",
              link: `/park-user-fees/${STATUS_REJECTED}`,
              Icon: <Description />,
            },
            {
              title: "Deffered Register",
              link: `/park-user-fees/${STATUS_DEFFERED}`,
              Icon: <Description />,
            },
            {
              title: sDeactivatedRegister,
              link: `/park-user-fees/${STATUS_DEACTIVATED}`,
              Icon: <DeactivateButton />,
            },
          ],
        },
        /* Property Tax */
        {
          title: sPropertyTax,
          Icon: <LocationCity />,
          // visible: !IsMarketAdmin(),
          visible: false,
          children: [
            {
              title: `${sNew} ${sPropertyTax}`,
              link: "/property-tax/create",
              visible: GetPermissions([CAN_ADMIN]),
              Icon: <Add />,
            },
            {
              title: sPendingRegister,
              link: `/property-tax/${STATUS_SUBMITTED}`,
              Icon: <Description />,
            },
            {
              title: sApprovedRegister,
              link: `/property-tax/${STATUS_ASSESSED}`,
              Icon: <Description />,
            },
            {
              title: sRejecetedRegister,
              link: `/property-tax/${STATUS_REJECTED}`,
              Icon: <Description />,
            },
            {
              title: sDefferedRegister,
              link: `/property-tax/${STATUS_DEFFERED}`,
              Icon: <Description />,
            },
            {
              title: sDeactivatedRegister,
              link: `/property-tax/${STATUS_DEACTIVATED}`,
              Icon: <DeactivateButton />,
            },
            {
              title: "Old Valuation Roll",
              link: `/property-tax/${OLD_VALUATION_ROLL}`,
              Icon: <History />,
            },
          ],
        },

        // Advance Tax Income
        {
          title: "Advance Tax Income",
          Icon: <ContentPasteGo />,
          visible: GetPermissions([CAN_ADMIN]) && !IsMarketAdmin(),
          children: [
            {
              title: "Create Original Assessment",
              link: "/advanced-tax-income/create",
              visible: !IsAuditAuthority(),
              Icon: <Add />,
            },
            {
              title: "Register",
              Icon: <Description />,
              link: "/advanced-tax-income",
            },
          ],
        },
        // Tendered Revenue
        {
          title: sTenderedRevenue,
          visible: GetPermissionsRoles(sTenderedRevenue) || !IsMarketAdmin(),
          Icon: <Sell />,
          children: [
            {
              title: `${sNew} Tenderer`,
              link: "/tenderer/create",
              visible: !IsAuditAuthority(),
              Icon: <Add />,
            },
            {
              title: sPendingRegister,
              link: `/tenderer/${TENDER_STATUS_PENDING}`,
              Icon: <Description />,
            },
            {
              title: sApprovedRegister,
              link: `/tenderer/${TENDER_STATUS_APPROVED}`,
              Icon: <Description />,
            },
            {
              title: sRejecetedRegister,
              link: `/tenderer/${TENDERER_STATUS_REJECTED}`,
              Icon: <Description />,
            },
            {
              title: sDefferedRegister,
              link: `/tenderer/${TENDERER_STATUS_DEFERRED}`,
              Icon: <Description />,
            },
            {
              title: sDeactivatedRegister,
              link: `/tenderer/${STATUS_DEACTIVATED}`,
              Icon: <DeactivateButton />,
            },
          ],
        },
      ],
    },

    /* Development Control */
    // {
    //   title: sDevelopmentControl,
    //   visible:
    //     (HasPermit() && !IsMarketAdmin()) ||
    //     GetPermissionsRoles(sDevelopmentControl),
    //   Icon: <WorkHistory />,
    //   children: [
        {
          title: "Applications Register",
          visible: true,
          Icon: <Sell />,
          link: `/all-applications/`,
        },
        {
          title: "Add Signature",
          visible: IsAdmin(),
          Icon: <Sell />,
          link: `/add-signature/`,
        },
        {
          title: "Minutes",
          visible: pending_decision,
          children: [
            {
              title: "New Minutes",
              Icon: <Add />,
              link: `/minutes/create`,
            },
            {
              title: "Minutes Register",
              Icon: <Description />,
              link: `/minutes`,
            },
          ],
          Icon: <Sell />,
        },
        {
          title: "Application Management",
          visible: GetPermissions([CAN_PROFESSIONAL]),
          children: [
            {
              title: "Development Permits Register",
              Icon: <List />,
              link: `/development-permit/`,
            },
            {
              title: "Occupation Permits Register",
              Icon: <List />,
              link: `/occupation-permit/`,
            },
            {
              title: "Building Permits Register",
              Icon: <List />,
              link: `/building-permit/`,
            },
            {
              title: "Subdivision/Consolidation Permits Register",
              Icon: <List />,
              link: `/subdivision-consolidation-permit/`,
            },
            {
              title: "Lease Application Register",
              Icon: <List />,
              link: `/lease-application/`,
            },
            {
              title: "Condominium Permits Register",
              Icon: <List />,
              link: `/condominium-permit/`,
            },
            {
              title: "Demolition Permits Register",
              Icon: <List />,
              link: `/demolition-permit/`,
            },
            {
              title: "Excavation Permits Register",
              Icon: <List />,
              link: `/excavation-permit/`,
            },
            {
              title: "Hoarding Permits Register",
              Icon: <List />,
              link: `/hoarding-permit/`,
            },
            {
              title: "Chainlink Permits Register",
              Icon: <List />,
              link: `/chainlink-permit/`,
            },
            {
              title: "Renovation Permits Register",
              Icon: <List />,
              link: `/renovation-permit/`,
            },
            {
              title: "Outdoor Advertisement Permits Register",
              Icon: <List />,
              link: `/outdoor-advertisement-permit/`,
            },
          ],
          Icon: <Sell />,
        },
        {
          title: "Routine Inspection",
          visible:
            GetPermissionsRoles("Routine Inspection") ||
            GetPermissions([CAN_PROFESSIONAL]),
          children: [
            {
              title: "New Routine Inspection",
              Icon: <Add />,
              link: `/routine-inspection/create`,
            },
            {
              title: "Routine Inspection",
              Icon: <Description />,
              link: `/routine-inspection`,
            },
          ],
          Icon: <Sell />,
        },
        {
          title: "Permit Enforcements",
          visible: GetPermissionsRoles("Enforcement"),
          children: [
            {
              title: "All",
              Icon: <Description />,
              link: `/enforcements/all`,
            },
            {
              title: "Compliant",
              Icon: <Description />,
              link: `/enforcements/compliant`,
            },
            {
              title: "Non Compliant",
              Icon: <Description />,
              link: `/enforcements/non compliant`,
            },
            {
              title: "Unscheduled",
              Icon: <Description />,
              link: `/enforcements/${UNSCHEDULED_STATUS}`,
            },
            {
              title: "Pending Decision",
              Icon: <Description />,
              link: `/enforcements/${PENDING_DECISION_STATUS}`,
            },
            // {
            //   title: "Prosecution",
            //   Icon: <Description />,
            //   link: `/routine-inspection`,
            // },
            {
              title: "Approved",
              Icon: <Description />,
              link: `/enforcements/${APPROVED_STATUS}`,
            },
            {
              title: "Deferred",
              Icon: <Description />,
              link: `/enforcements/${DEFERRED_STATUS}`,
            },
            {
              title: "Rejected",
              Icon: <Description />,
              link: `/enforcements/${REJECTED_STATUS}`,
            },
          ],
          Icon: <Sell />,
        },
        {
          title: "Notifications",
          visible: true,
          children: [
            {
              title: "Notification of Commencement of works",
              // visible: GetPermissionsRoles("Routine Inspection"),
              children: [
                {
                  title: "New Notification",
                  Icon: <Add />,
                  link: `/notification-of-commencement/create`,
                },
                {
                  title: "Notifications Register",
                  Icon: <Description />,
                  link: `/notification-of-commencement`,
                },
              ],
              Icon: <List />,
            },
            {
              title: "Notification of request for inspection",
              // visible: GetPermissionsRoles("Routine Inspection"),
              children: [
                {
                  title: "New Notification",
                  Icon: <Add />,
                  link: `/requirement-for-inspection/create`,
                },
                {
                  title: "Notifications Register",
                  Icon: <Description />,
                  link: `/requirement-for-inspection`,
                },
              ],
              Icon: <List />,
            },
          ],
          Icon: <Sell />,
        },
        {
          title: "Development Permit",
          visible: GetPermissionsRoles("Development Permit"),
          Icon: <Sell />,
          children: [
            {
              title: "Applications",
              Icon: <List />,
              children: [
                {
                  title: "Apply for Development Permit",
                  Icon: <Add />,
                  link: "/development-permit/create",
                  visible: GetPermissions([CAN_PUBLIC]),
                },
                {
                  title: "Pending Applications",
                  Icon: <Description />,
                  visible: GetPermissionsRoles(
                    STATUS_SUBMITTED,
                    sDevelopmentPermit
                  ),
                  link: `/development-permit/${STATUS_SUBMITTED}`,
                },
                {
                  title: "Sent Back Applications",
                  Icon: <Description />,
                  visible: GetPermissionsRoles(
                    SENT_BACK_STATUS,
                    sDevelopmentPermit
                  ),
                  link: `/development-permit/${SENT_BACK_STATUS}`,
                },
                {
                  title: "Pending Assessment",
                  Icon: <Description />,
                  visible: GetPermissionsRoles(
                    PENDING_ASSESSMENT_STATUS,
                    sDevelopmentPermit
                  ),
                  link: `/development-permit/${PENDING_ASSESSMENT_STATUS}`,
                },
                {
                  title: "Applications Under Scrutiny",
                  visible: GetPermissionsRoles(
                    APPLICATIONS_UNDER_SCRUTINY,
                    sDevelopmentPermit
                  ),
                  children: [
                    {
                      title: "Unscheduled",
                      visible: GetPermissionsRoles(
                        UNSCHEDULED_STATUS,
                        sDevelopmentPermit
                      ),
                      link: `/development-permit/${UNSCHEDULED_STATUS}`,
                    },
                    {
                      title: "Pending Inspection",
                      visible: GetPermissionsRoles(
                        UNDER_INSPECTION_STATUS,
                        sDevelopmentPermit
                      ),
                      link: `/development-permit/${UNDER_INSPECTION_STATUS}`,
                    },
                    {
                      title: "Inspected",
                      visible: GetPermissionsRoles(
                        INSPECTED_STATUS,
                        sDevelopmentPermit
                      ),
                      link: `/development-permit/${INSPECTED_STATUS}`,
                    },
                    {
                      title: "Reviewed",
                      visible: GetPermissionsRoles(
                        REVIEWED_STATUS,
                        sDevelopmentPermit
                      ),
                      link: `/development-permit/${REVIEWED_STATUS}`,
                    },
                    {
                      title: "Pending PPC Consideration",
                      visible: GetPermissionsRoles(
                        RECOMMENDED_STATUS,
                        sDevelopmentPermit
                      ),
                      link: `/development-permit/${RECOMMENDED_STATUS}`,
                    },
                    // {
                    //   title: "Recommendation",
                    //   link: `/development-permit/${RECOMMENDED_STATUS}`,
                    // },
                  ],
                  Icon: <Description />,
                },
                {
                  title: "Pending Decision",
                  Icon: <Description />,
                  visible: GetPermissionsRoles(
                    PENDING_DECISION_STATUS,
                    sDevelopmentPermit
                  ),
                  link: `/development-permit/${PENDING_DECISION_STATUS}`,
                },
                {
                  title: "Approved Applications",
                  Icon: <Description />,
                  visible: GetPermissionsRoles(
                    APPROVED_STATUS,
                    sDevelopmentPermit
                  ),
                  link: `/development-permit/${APPROVED_STATUS}`,
                },
                {
                  title: "Deferred  Applications",
                  Icon: <Description />,
                  visible: GetPermissionsRoles(
                    DEFERRED_STATUS,
                    sDevelopmentPermit
                  ),
                  link: `/development-permit/${DEFERRED_STATUS}`,
                },
                {
                  title: "Rejected  Applications",
                  Icon: <Description />,
                  visible: GetPermissionsRoles(
                    REJECTED_STATUS,
                    sDevelopmentPermit
                  ),
                  link: `/development-permit/${REJECTED_STATUS}`,
                },
              ],
            },
            {
              title: "Paid Registers",
              Icon: <List />,
              link: `/development-permit/${PAID}`,
            },
          ],
        },
        {
          title: "Telecommunincation Mast Permit",
          visible: GetPermissionsRoles("Development Permit"),
          Icon: <Sell />,
          children: [
            {
              title: "Applications",
              Icon: <List />,
              children: [
                {
                  title: "Apply for Telecom Mast Permit",
                  Icon: <Add />,
                  link: "/telecommunication-mast-permit/create",
                  visible: GetPermissions([CAN_PUBLIC]),
                },
                {
                  title: "Pending Applications",
                  Icon: <Description />,
                  visible: GetPermissionsRoles(
                    STATUS_SUBMITTED,
                    sDevelopmentPermit
                  ),
                  link: `/telecommunication-mast-permit/${STATUS_SUBMITTED}`,
                },
                {
                  title: "Sent Back Applications",
                  Icon: <Description />,
                  visible: GetPermissionsRoles(
                    SENT_BACK_STATUS,
                    sDevelopmentPermit
                  ),
                  link: `/telecommunication-mast-permit/${SENT_BACK_STATUS}`,
                },
                {
                  title: "Pending Assessment",
                  Icon: <Description />,
                  visible: GetPermissionsRoles(
                    PENDING_ASSESSMENT_STATUS,
                    sDevelopmentPermit
                  ),
                  link: `/telecommunication-mast-permit/${PENDING_ASSESSMENT_STATUS}`,
                },
                {
                  title: "Applications Under Scrutiny",
                  visible: GetPermissionsRoles(
                    APPLICATIONS_UNDER_SCRUTINY,
                    sDevelopmentPermit
                  ),
                  children: [
                    {
                      title: "Unscheduled",
                      visible: GetPermissionsRoles(
                        UNSCHEDULED_STATUS,
                        sDevelopmentPermit
                      ),
                      link: `/telecommunication-mast-permit/${UNSCHEDULED_STATUS}`,
                    },
                    {
                      title: "Pending Inspection",
                      visible: GetPermissionsRoles(
                        UNDER_INSPECTION_STATUS,
                        sDevelopmentPermit
                      ),
                      link: `/telecommunication-mast-permit/${UNDER_INSPECTION_STATUS}`,
                    },
                    {
                      title: "Inspected",
                      visible: GetPermissionsRoles(
                        INSPECTED_STATUS,
                        sDevelopmentPermit
                      ),
                      link: `/telecommunication-mast-permit/${INSPECTED_STATUS}`,
                    },
                    {
                      title: "Reviewed",
                      visible: GetPermissionsRoles(
                        REVIEWED_STATUS,
                        sDevelopmentPermit
                      ),
                      link: `/telecommunication-mast-permit/${REVIEWED_STATUS}`,
                    },
                    {
                      title: "Pending PPC Consideration",
                      visible: GetPermissionsRoles(
                        RECOMMENDED_STATUS,
                        sDevelopmentPermit
                      ),
                      link: `/telecommunication-mast-permit/${RECOMMENDED_STATUS}`,
                    },
                    // {
                    //   title: "Recommendation",
                    //   link: `/development-permit/${RECOMMENDED_STATUS}`,
                    // },
                  ],
                  Icon: <Description />,
                },
                {
                  title: "Pending Decision",
                  Icon: <Description />,
                  visible: GetPermissionsRoles(
                    PENDING_DECISION_STATUS,
                    sDevelopmentPermit
                  ),
                  link: `/telecommunication-mast-permit/${PENDING_DECISION_STATUS}`,
                },
                {
                  title: "Approved Applications",
                  Icon: <Description />,
                  visible: GetPermissionsRoles(
                    APPROVED_STATUS,
                    sDevelopmentPermit
                  ),
                  link: `/telecommunication-mast-permit/${APPROVED_STATUS}`,
                },
                {
                  title: "Deferred  Applications",
                  Icon: <Description />,
                  visible: GetPermissionsRoles(
                    DEFERRED_STATUS,
                    sDevelopmentPermit
                  ),
                  link: `/telecommunication-mast-permit/${DEFERRED_STATUS}`,
                },
                {
                  title: "Rejected  Applications",
                  Icon: <Description />,
                  visible: GetPermissionsRoles(
                    REJECTED_STATUS,
                    sDevelopmentPermit
                  ),
                  link: `/telecommunication-mast-permit/${REJECTED_STATUS}`,
                },
              ],
            },
            {
              title: "Paid Registers",
              Icon: <List />,
              link: `/telecommunication-mast-permit/${PAID}`,
            },
          ],
        },
        {
          title: "Occupation Permit",
          visible:
            GetPermissionsRoles("Occupation Permit") &&
            !GetPermissions([CAN_PUBLIC]),
          Icon: <Sell />,
          children: [
            {
              title: "Applications",
              Icon: <List />,
              children: [
                {
                  title: "Apply for Occupation Permit",
                  Icon: <Add />,
                  link: "/occupational-permit/create",
                  visible: GetPermissions([CAN_PUBLIC]),
                },
                {
                  title: "Pending Applications",
                  Icon: <Description />,
                  link: `/occupational-permit/${PENDING_STATUS}`,
                  visible: GetPermissionsRoles(
                    STATUS_SUBMITTED,
                    sOccupationPermit
                  ),
                },
                {
                  title: "Sent Back Applications",
                  Icon: <Description />,
                  link: `/occupational-permit/${SENT_BACK_STATUS}`,
                  visible: GetPermissionsRoles(
                    SENT_BACK_STATUS,
                    sOccupationPermit
                  ),
                },

                {
                  title: "Pending Assessment",
                  Icon: <Description />,
                  link: `/occupational-permit/${PENDING_ASSESSMENT_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_ASSESSMENT_STATUS,
                    sOccupationPermit
                  ),
                },
                {
                  title: "Applications Under Scrutiny",
                  visible: GetPermissionsRoles(
                    APPLICATIONS_UNDER_SCRUTINY,
                    sOccupationPermit
                  ),
                  children: [
                    {
                      title: "Unscheduled",
                      link: `/occupational-permit/${UNSCHEDULED_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNSCHEDULED_STATUS,
                        sOccupationPermit
                      ),
                    },
                    {
                      title: "Pending Inspection",
                      link: `/occupational-permit/${UNDER_INSPECTION_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNDER_INSPECTION_STATUS,
                        sOccupationPermit
                      ),
                    },
                    {
                      title: "Inspected",
                      link: `/occupational-permit/${INSPECTED_STATUS}`,
                      visible: GetPermissionsRoles(
                        INSPECTED_STATUS,
                        sOccupationPermit
                      ),
                    },
                    {
                      title: "Reviewed",
                      link: `/occupational-permit/${REVIEWED_STATUS}`,
                      visible: GetPermissionsRoles(
                        REVIEWED_STATUS,
                        sOccupationPermit
                      ),
                    },
                    {
                      title: "Pending BC Consideration",
                      link: `/occupational-permit/${RECOMMENDED_STATUS}`,
                      visible: GetPermissionsRoles(
                        RECOMMENDED_STATUS,
                        sOccupationPermit
                      ),
                    },
                  ],
                  Icon: <Description />,
                },
                {
                  title: "Pending Decision",
                  Icon: <Description />,
                  link: `/occupational-permit/${PENDING_DECISION_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_DECISION_STATUS,
                    sOccupationPermit
                  ),
                },
                {
                  title: "Approved Applications",
                  Icon: <Description />,
                  link: `/occupational-permit/${APPROVED_STATUS}`,
                  visible: GetPermissionsRoles(
                    APPROVED_STATUS,
                    sOccupationPermit
                  ),
                },
                {
                  title: "Deferred Applications",
                  Icon: <Description />,
                  link: `/occupational-permit/${DEFERRED_STATUS}`,
                  visible: GetPermissionsRoles(
                    DEFERRED_STATUS,
                    sOccupationPermit
                  ),
                },
                {
                  title: "Rejected  Applications",
                  Icon: <Description />,
                  link: `/occupational-permit/${REJECTED_STATUS}`,
                  visible: GetPermissionsRoles(
                    REJECTED_STATUS,
                    sOccupationPermit
                  ),
                },
              ],
            },

            {
              title: "Paid Registers",
              Icon: <List />,
              link: `/occupational-permit/${PAID}`,
            },
          ],
        },
        {
          visible:
            GetPermissionsRoles(sBuildingOperation) &&
            !GetPermissions([CAN_PUBLIC]),
          title: "Building Permit",
          Icon: <Sell />,
          children: [
            {
              title: "Applications",
              Icon: <List />,
              children: [
                {
                  title: "Apply for Building Permit",
                  Icon: <Add />,
                  link: "/building-permit/create",
                  visible: GetPermissions([CAN_PUBLIC]),
                },
                {
                  title: "Pending Applications",
                  Icon: <Description />,
                  link: `/building-permit/${STATUS_SUBMITTED}`,
                  visible: GetPermissionsRoles(
                    STATUS_SUBMITTED,
                    sBuildingOperation
                  ),
                },
                {
                  title: "Sent Back Applications",
                  Icon: <Description />,
                  link: `/building-permit/${SENT_BACK_STATUS}`,
                  visible: GetPermissionsRoles(
                    SENT_BACK_STATUS,
                    sBuildingOperation
                  ),
                },
                {
                  title: "Pending Assessment",
                  Icon: <Description />,
                  link: `/building-permit/${PENDING_ASSESSMENT_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_ASSESSMENT_STATUS,
                    sBuildingOperation
                  ),
                },
                {
                  title: "Applications Under Scrutiny",
                  visible: GetPermissionsRoles(
                    APPLICATIONS_UNDER_SCRUTINY,
                    sBuildingOperation
                  ),
                  children: [
                    {
                      title: "Unscheduled",
                      link: `/building-permit/${UNSCHEDULED_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNSCHEDULED_STATUS,
                        sBuildingOperation
                      ),
                    },
                    {
                      title: "Pending Inspection",
                      link: `/building-permit/${UNDER_INSPECTION_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNDER_INSPECTION_STATUS,
                        sBuildingOperation
                      ),
                    },
                    {
                      title: "Inspected",
                      link: `/building-permit/${INSPECTED_STATUS}`,
                      visible: GetPermissionsRoles(
                        INSPECTED_STATUS,
                        sBuildingOperation
                      ),
                    },
                    {
                      title: "Reviewed",
                      link: `/building-permit/${REVIEWED_STATUS}`,
                      visible: GetPermissionsRoles(
                        REVIEWED_STATUS,
                        sBuildingOperation
                      ),
                    },
                    {
                      title: "Pending BC Consideration",
                      link: `/building-permit/${RECOMMENDED_STATUS}`,
                      visible: GetPermissionsRoles(
                        RECOMMENDED_STATUS,
                        sBuildingOperation
                      ),
                    },
                  ],
                  Icon: <Description />,
                },
                {
                  title: "Pending Decision",
                  Icon: <Description />,
                  link: `/building-permit/${PENDING_DECISION_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_DECISION_STATUS,
                    sBuildingOperation
                  ),
                },
                {
                  title: "Approved Applications",
                  Icon: <Description />,
                  link: `/building-permit/${APPROVED_STATUS}`,
                  visible: GetPermissionsRoles(
                    APPROVED_STATUS,
                    sBuildingOperation
                  ),
                },
                {
                  title: "Deferred Applications",
                  Icon: <Description />,
                  link: `/building-permit/${DEFERRED_STATUS}`,
                  visible: GetPermissionsRoles(
                    DEFERRED_STATUS,
                    sBuildingOperation
                  ),
                },
                {
                  title: "Rejected  Applications",
                  Icon: <Description />,
                  link: `/building-permit/${REJECTED_STATUS}`,
                  visible: GetPermissionsRoles(
                    REJECTED_STATUS,
                    sBuildingOperation
                  ),
                },
              ],
            },
            {
              title: "Paid Registers",
              Icon: <List />,
              link: `/building-permit/${PAID}`,
            },
          ],
        },
        {
          visible: GetPermissionsRoles(sOutdoorAdvertisementPermit),
          title: "Outdoor Advertisement Permit",
          Icon: <Sell />,
          children: [
            {
              title: "Applications",
              Icon: <List />,
              children: [
                {
                  title: "Apply for Outdoor Advertisement Permit",
                  Icon: <Add />,
                  link: "/outdoor-advertisement-permit/create",
                  visible: GetPermissions([CAN_PUBLIC]),
                },
                {
                  title: "Pending Applications",
                  Icon: <Description />,
                  link: `/outdoor-advertisement-permit/${PENDING_STATUS}`,
                  visible: GetPermissionsRoles(
                    STATUS_SUBMITTED,
                    sOutdoorAdvertisementPermit
                  ),
                },
                {
                  title: "Sent Back Applications",
                  Icon: <Description />,
                  link: `/outdoor-advertisement-permit/${SENT_BACK_STATUS}`,
                  visible: GetPermissionsRoles(
                    SENT_BACK_STATUS,
                    sOutdoorAdvertisementPermit
                  ),
                },

                {
                  title: "Pending Assessment",
                  Icon: <Description />,
                  link: `/outdoor-advertisement-permit/${PENDING_ASSESSMENT_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_ASSESSMENT_STATUS,
                    sOutdoorAdvertisementPermit
                  ),
                },
                {
                  title: "Applications Under Scrutiny",
                  visible: GetPermissionsRoles(
                    APPLICATIONS_UNDER_SCRUTINY,
                    sOutdoorAdvertisementPermit
                  ),
                  children: [
                    {
                      title: "Unscheduled",
                      link: `/outdoor-advertisement-permit/${UNSCHEDULED_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNSCHEDULED_STATUS,
                        sOutdoorAdvertisementPermit
                      ),
                    },
                    {
                      title: "Pending Inspection",
                      link: `/outdoor-advertisement-permit/${UNDER_INSPECTION_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNDER_INSPECTION_STATUS,
                        sOutdoorAdvertisementPermit
                      ),
                    },
                    {
                      title: "Inspected",
                      link: `/outdoor-advertisement-permit/${INSPECTED_STATUS}`,
                      visible: GetPermissionsRoles(
                        INSPECTED_STATUS,
                        sOutdoorAdvertisementPermit
                      ),
                    },
                    {
                      title: "Reviewed",
                      link: `/outdoor-advertisement-permit/${REVIEWED_STATUS}`,
                      visible: GetPermissionsRoles(
                        REVIEWED_STATUS,
                        sOutdoorAdvertisementPermit
                      ),
                    },
                    {
                      title: "Pending PPC Consideration",
                      link: `/outdoor-advertisement-permit/${RECOMMENDED_STATUS}`,
                      visible: GetPermissionsRoles(
                        RECOMMENDED_STATUS,
                        sOutdoorAdvertisementPermit
                      ),
                    },
                    // {
                    //   title: "Recommended",
                    //   link: `/outdoor-advertisement-permit/${RECOMMENDED_STATUS}`,
                    // },
                  ],
                  Icon: <Description />,
                },
                {
                  title: "Pending Decision",
                  Icon: <Description />,
                  link: `/outdoor-advertisement-permit/${PENDING_DECISION_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_DECISION_STATUS,
                    sOutdoorAdvertisementPermit
                  ),
                },
                {
                  title: "Approved Applications",
                  Icon: <Description />,
                  link: `/outdoor-advertisement-permit/${APPROVED_STATUS}`,
                  visible: GetPermissionsRoles(
                    APPROVED_STATUS,
                    sOutdoorAdvertisementPermit
                  ),
                },
                {
                  title: "Deferred Applications",
                  Icon: <Description />,
                  link: `/outdoor-advertisement-permit/${DEFERRED_STATUS}`,
                  visible: GetPermissionsRoles(
                    DEFERRED_STATUS,
                    sOutdoorAdvertisementPermit
                  ),
                },
                {
                  title: "Rejected  Applications",
                  Icon: <Description />,
                  link: `/outdoor-advertisement-permit/${REJECTED_STATUS}`,
                  visible: GetPermissionsRoles(
                    REJECTED_STATUS,
                    sOutdoorAdvertisementPermit
                  ),
                },
              ],
            },

            {
              title: "Paid Registers",
              Icon: <List />,
              link: `/outdoor-advertisement-permit/${PAID}`,
            },
          ],
        },

        {
          visible: GetPermissionsRoles(sSubdivisionConsolidationPermit),
          title: "Subdivision/Consolidation Permit",
          Icon: <Sell />,
          children: [
            {
              title: "Applications",
              Icon: <List />,
              children: [
                {
                  title: "Apply for Subdivision Permit",
                  Icon: <Add />,
                  link: "/subdivision-consolidation-permits/create",
                  visible: GetPermissions([CAN_PUBLIC]),
                },
                {
                  title: "Pending Applications",
                  Icon: <Description />,
                  link: `/subdivision-consolidation-permits/${STATUS_SUBMITTED}`,
                  visible: GetPermissionsRoles(
                    STATUS_SUBMITTED,
                    sSubdivisionConsolidationPermit
                  ),
                },
                {
                  title: "Sent Back Applications",
                  Icon: <Description />,
                  link: `/subdivision-consolidation-permits/${SENT_BACK_STATUS}`,
                  visible: GetPermissionsRoles(
                    SENT_BACK_STATUS,
                    sSubdivisionConsolidationPermit
                  ),
                },
                {
                  title: "Pending Assessment",
                  Icon: <Description />,
                  link: `/subdivision-consolidation-permits/${PENDING_ASSESSMENT_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_ASSESSMENT_STATUS,
                    sSubdivisionConsolidationPermit
                  ),
                },
                {
                  title: "Applications Under Scrutiny",
                  visible: GetPermissionsRoles(
                    APPLICATIONS_UNDER_SCRUTINY,
                    sSubdivisionConsolidationPermit
                  ),
                  children: [
                    {
                      title: "Unscheduled",
                      link: `/subdivision-consolidation-permits/${UNSCHEDULED_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNSCHEDULED_STATUS,
                        sSubdivisionConsolidationPermit
                      ),
                    },
                    {
                      title: "Pending Inspection",
                      link: `/subdivision-consolidation-permits/${UNDER_INSPECTION_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNDER_INSPECTION_STATUS,
                        sSubdivisionConsolidationPermit
                      ),
                    },
                    {
                      title: "Inspected",
                      link: `/subdivision-consolidation-permits/${INSPECTED_STATUS}`,
                      visible: GetPermissionsRoles(
                        INSPECTED_STATUS,
                        sSubdivisionConsolidationPermit
                      ),
                    },
                    {
                      title: "Reviewed",
                      link: `/subdivision-consolidation-permits/${REVIEWED_STATUS}`,
                      visible: GetPermissionsRoles(
                        REVIEWED_STATUS,
                        sSubdivisionConsolidationPermit
                      ),
                    },
                    {
                      title: "Pending PPC Consideration",
                      link: `/subdivision-consolidation-permits/${RECOMMENDED_STATUS}`,
                      visible: GetPermissionsRoles(
                        RECOMMENDED_STATUS,
                        sSubdivisionConsolidationPermit
                      ),
                    },
                    // {
                    //   title: "Recommended",
                    //   link: `/outdoor-advertisement-permit/${RECOMMENDED_STATUS}`,
                    // },
                  ],
                  Icon: <Description />,
                },
                {
                  title: "Pending Decision",
                  Icon: <Description />,
                  link: `/subdivision-consolidation-permits/${PENDING_DECISION_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_DECISION_STATUS,
                    sSubdivisionConsolidationPermit
                  ),
                },
                {
                  title: "Approved Applications",
                  Icon: <Description />,
                  link: `/subdivision-consolidation-permits/${APPROVED_STATUS}`,
                  visible: GetPermissionsRoles(
                    APPROVED_STATUS,
                    sSubdivisionConsolidationPermit
                  ),
                },
                {
                  title: "Deferred Applications",
                  Icon: <Description />,
                  link: `/subdivision-consolidation-permits/${DEFERRED_STATUS}`,
                  visible: GetPermissionsRoles(
                    DEFERRED_STATUS,
                    sSubdivisionConsolidationPermit
                  ),
                },
                {
                  title: "Rejected  Applications",
                  Icon: <Description />,
                  link: `/subdivision-consolidation-permits/${REJECTED_STATUS}`,
                  visible: GetPermissionsRoles(
                    REJECTED_STATUS,
                    sSubdivisionConsolidationPermit
                  ),
                },
              ],
            },
            {
              title: "Paid Registers",
              Icon: <List />,
              link: `/subdivision-consolidation-permits/${PAID}`,
            },
          ],
        },
        {
          visible: GetPermissionsRoles(sLeaseApplication),
          title: "Lease Application",
          Icon: <Sell />,
          children: [
            {
              title: "Applications",
              Icon: <List />,
              children: [
                {
                  title: "Apply for Lease Application",
                  Icon: <Add />,
                  link: "/lease-application/create",
                  visible: GetPermissions([CAN_PUBLIC]),
                },
                {
                  title: "Pending Applications",
                  Icon: <Description />,
                  link: `/lease-application/${STATUS_SUBMITTED}`,
                  visible: GetPermissionsRoles(
                    STATUS_SUBMITTED,
                    sLeaseApplication
                  ),
                },
                {
                  title: "Sent Back Applications",
                  Icon: <Description />,
                  link: `/lease-application/${SENT_BACK_STATUS}`,
                  visible: GetPermissionsRoles(
                    SENT_BACK_STATUS,
                    sLeaseApplication
                  ),
                },
                {
                  title: "Pending Assessment",
                  Icon: <Description />,
                  link: `/lease-application/${PENDING_ASSESSMENT_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_ASSESSMENT_STATUS,
                    sLeaseApplication
                  ),
                },
                {
                  title: "Applications Under Scrutiny",
                  visible: GetPermissionsRoles(
                    APPLICATIONS_UNDER_SCRUTINY,
                    sLeaseApplication
                  ),
                  children: [
                    {
                      title: "Unscheduled",
                      link: `/lease-application/${UNSCHEDULED_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNSCHEDULED_STATUS,
                        sLeaseApplication
                      ),
                    },
                    {
                      title: "Pending Inspection",
                      link: `/lease-application/${UNDER_INSPECTION_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNDER_INSPECTION_STATUS,
                        sLeaseApplication
                      ),
                    },
                    {
                      title: "Inspected",
                      link: `/lease-application/${INSPECTED_STATUS}`,
                      visible: GetPermissionsRoles(
                        INSPECTED_STATUS,
                        sLeaseApplication
                      ),
                    },
                    {
                      title: "Reviewed",
                      link: `/lease-application/${REVIEWED_STATUS}`,
                      visible: GetPermissionsRoles(
                        REVIEWED_STATUS,
                        sLeaseApplication
                      ),
                    },
                    {
                      title: "Pending PPC Consideration",
                      link: `/lease-application/${RECOMMENDED_STATUS}`,
                      visible: GetPermissionsRoles(
                        RECOMMENDED_STATUS,
                        sLeaseApplication
                      ),
                    },
                    // {
                    //   title: "Recommended",
                    //   link: `/lease-application/${RECOMMENDED_STATUS}`,
                    // },
                  ],
                  Icon: <Description />,
                },
                {
                  title: "Pending Decision",
                  Icon: <Description />,
                  link: `/lease-application/${PENDING_DECISION_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_DECISION_STATUS,
                    sLeaseApplication
                  ),
                },
                {
                  title: "Approved Applications",
                  Icon: <Description />,
                  link: `/lease-application/${APPROVED_STATUS}`,
                  visible: GetPermissionsRoles(
                    APPROVED_STATUS,
                    sLeaseApplication
                  ),
                },
                {
                  title: "Deferred Applications",
                  Icon: <Description />,
                  link: `/lease-application/${DEFERRED_STATUS}`,
                  visible: GetPermissionsRoles(
                    DEFERRED_STATUS,
                    sLeaseApplication
                  ),
                },
                {
                  title: "Rejected  Applications",
                  Icon: <Description />,
                  link: `/lease-application/${REJECTED_STATUS}`,
                  visible: GetPermissionsRoles(
                    REJECTED_STATUS,
                    sLeaseApplication
                  ),
                },
              ],
            },
            {
              title: "Paid Registers",
              Icon: <List />,
              link: `/lease-application/${PAID}`,
            },
          ],
        },
        {
          visible: GetPermissionsRoles(sLeaseApplication),
          title: "Change of Land Use Permit",
          Icon: <Sell />,
          children: [
            {
              title: "Applications",
              Icon: <List />,
              children: [
                {
                  title: "Apply for Change of Land Use",
                  Icon: <Add />,
                  link: "/change-of-land-use-permit/create",
                  visible: GetPermissions([CAN_PUBLIC]),
                },
                {
                  title: "Pending Applications",
                  Icon: <Description />,
                  link: `/change-of-land-use-permit/${STATUS_SUBMITTED}`,
                  visible: GetPermissionsRoles(
                    STATUS_SUBMITTED,
                    sLeaseApplication
                  ),
                },
                {
                  title: "Sent Back Applications",
                  Icon: <Description />,
                  link: `/change-of-land-use-permit/${SENT_BACK_STATUS}`,
                  visible: GetPermissionsRoles(
                    SENT_BACK_STATUS,
                    sLeaseApplication
                  ),
                },
                {
                  title: "Pending Assessment",
                  Icon: <Description />,
                  link: `/change-of-land-use-permit/${PENDING_ASSESSMENT_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_ASSESSMENT_STATUS,
                    sLeaseApplication
                  ),
                },
                {
                  title: "Applications Under Scrutiny",
                  visible: GetPermissionsRoles(
                    APPLICATIONS_UNDER_SCRUTINY,
                    sLeaseApplication
                  ),
                  children: [
                    {
                      title: "Unscheduled",
                      link: `/change-of-land-use-permit/${UNSCHEDULED_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNSCHEDULED_STATUS,
                        sLeaseApplication
                      ),
                    },
                    {
                      title: "Pending Inspection",
                      link: `/change-of-land-use-permit/${UNDER_INSPECTION_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNDER_INSPECTION_STATUS,
                        sLeaseApplication
                      ),
                    },
                    {
                      title: "Inspected",
                      link: `/change-of-land-use-permit/${INSPECTED_STATUS}`,
                      visible: GetPermissionsRoles(
                        INSPECTED_STATUS,
                        sLeaseApplication
                      ),
                    },
                    {
                      title: "Reviewed",
                      link: `/change-of-land-use-permit/${REVIEWED_STATUS}`,
                      visible: GetPermissionsRoles(
                        REVIEWED_STATUS,
                        sLeaseApplication
                      ),
                    },
                    {
                      title: "Pending PPC Consideration",
                      link: `/change-of-land-use-permit/${RECOMMENDED_STATUS}`,
                      visible: GetPermissionsRoles(
                        RECOMMENDED_STATUS,
                        sLeaseApplication
                      ),
                    },
                    // {
                    //   title: "Recommended",
                    //   link: `/lease-application/${RECOMMENDED_STATUS}`,
                    // },
                  ],
                  Icon: <Description />,
                },
                {
                  title: "Pending Decision",
                  Icon: <Description />,
                  link: `/change-of-land-use-permit/${PENDING_DECISION_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_DECISION_STATUS,
                    sLeaseApplication
                  ),
                },
                {
                  title: "Approved Applications",
                  Icon: <Description />,
                  link: `/change-of-land-use-permit/${APPROVED_STATUS}`,
                  visible: GetPermissionsRoles(
                    APPROVED_STATUS,
                    sLeaseApplication
                  ),
                },
                {
                  title: "Deferred Applications",
                  Icon: <Description />,
                  link: `/change-of-land-use-permit/${DEFERRED_STATUS}`,
                  visible: GetPermissionsRoles(
                    DEFERRED_STATUS,
                    sLeaseApplication
                  ),
                },
                {
                  title: "Rejected  Applications",
                  Icon: <Description />,
                  link: `/change-of-land-use-permit/${REJECTED_STATUS}`,
                  visible: GetPermissionsRoles(
                    REJECTED_STATUS,
                    sLeaseApplication
                  ),
                },
              ],
            },
            {
              title: "Paid Registers",
              Icon: <List />,
              link: `/change-of-land-use-permit/${PAID}`,
            },
          ],
        },
        {
          visible:
            GetPermissionsRoles(sCondominiumPermit) ||
            GetPermissions([CAN_PUBLIC]),
          title: "Condominium Permit",
          Icon: <Sell />,
          children: [
            {
              title: "Applications",
              Icon: <List />,
              children: [
                {
                  title: "Apply for Condominium Permit",
                  Icon: <Add />,
                  link: "/condominium-permit/create",
                  visible: GetPermissions([CAN_PUBLIC]),
                },
                {
                  title: "Pending Applications",
                  Icon: <Description />,
                  link: `/condominium-permit/${STATUS_SUBMITTED}`,
                  visible: GetPermissionsRoles(
                    STATUS_SUBMITTED,
                    sCondominiumPermit
                  ),
                },
                {
                  title: "Sent Back Applications",
                  Icon: <Description />,
                  link: `/condominium-permit/${SENT_BACK_STATUS}`,
                  visible: GetPermissionsRoles(
                    SENT_BACK_STATUS,
                    sCondominiumPermit
                  ),
                },

                {
                  title: "Pending Assessment",
                  Icon: <Description />,
                  link: `/condominium-permit/${PENDING_ASSESSMENT_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_ASSESSMENT_STATUS,
                    sCondominiumPermit
                  ),
                },
                {
                  title: "Applications Under Scrutiny",
                  visible: GetPermissionsRoles(
                    APPLICATIONS_UNDER_SCRUTINY,
                    sCondominiumPermit
                  ),
                  children: [
                    {
                      title: "Unscheduled",
                      link: `/condominium-permit/${UNSCHEDULED_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNSCHEDULED_STATUS,
                        sCondominiumPermit
                      ),
                    },
                    {
                      title: "Pending Inspection",
                      link: `/condominium-permit/${UNDER_INSPECTION_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNDER_INSPECTION_STATUS,
                        sCondominiumPermit
                      ),
                    },
                    {
                      title: "Inspected",
                      link: `/condominium-permit/${INSPECTED_STATUS}`,
                      visible: GetPermissionsRoles(
                        INSPECTED_STATUS,
                        sCondominiumPermit
                      ),
                    },
                    {
                      title: "Reviewed",
                      link: `/condominium-permit/${REVIEWED_STATUS}`,
                      visible: GetPermissionsRoles(
                        REVIEWED_STATUS,
                        sCondominiumPermit
                      ),
                    },
                    {
                      title: "Pending PPC Consideration",
                      link: `/condominium-permit/${RECOMMENDED_STATUS}`,
                      visible: GetPermissionsRoles(
                        RECOMMENDED_STATUS,
                        sCondominiumPermit
                      ),
                    },
                    // {
                    //   title: "Recommended",
                    //   link: `/lease-application/${RECOMMENDED_STATUS}`,
                    // },
                  ],
                  Icon: <Description />,
                },
                {
                  title: "Pending Decision",
                  Icon: <Description />,
                  link: `/condominium-permit/${PENDING_DECISION_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_DECISION_STATUS,
                    sCondominiumPermit
                  ),
                },
                {
                  title: "Approved Applications",
                  Icon: <Description />,
                  link: `/condominium-permit/${APPROVED_STATUS}`,
                  visible: GetPermissionsRoles(
                    APPROVED_STATUS,
                    sCondominiumPermit
                  ),
                },
                {
                  title: "Deferred Applications",
                  Icon: <Description />,
                  link: `/condominium-permit/${DEFERRED_STATUS}`,
                  visible: GetPermissionsRoles(
                    DEFERRED_STATUS,
                    sCondominiumPermit
                  ),
                },
                {
                  title: "Rejected  Applications",
                  Icon: <Description />,
                  link: `/condominium-permit/${REJECTED_STATUS}`,
                  visible: GetPermissionsRoles(
                    REJECTED_STATUS,
                    sCondominiumPermit
                  ),
                },
              ],
            },

            {
              title: "Paid Registers",
              Icon: <List />,
              link: `/condominium-permit/${PAID}`,
            },
          ],
        },
        {
          visible:
            GetPermissionsRoles(sDemolitionPermit) &&
            !GetPermissions([CAN_PUBLIC]),
          title: "Demolition Permit",
          Icon: <Sell />,
          children: [
            {
              title: "Applications",
              Icon: <List />,
              children: [
                {
                  title: "Apply for Demolition Permit",
                  Icon: <Add />,
                  link: "/demolition-permit/create",
                  visible: GetPermissions([CAN_PUBLIC]),
                },
                {
                  title: "Pending Applications",
                  Icon: <Description />,
                  link: `/demolition-permit/${PENDING_STATUS}`,
                  visible: GetPermissionsRoles(
                    STATUS_SUBMITTED,
                    sDemolitionPermit
                  ),
                },
                {
                  title: "Sent Back Applications",
                  Icon: <Description />,
                  link: `/demolition-permit/${SENT_BACK_STATUS}`,
                  visible: GetPermissionsRoles(
                    SENT_BACK_STATUS,
                    sDemolitionPermit
                  ),
                },
                {
                  title: "Pending Assessment",
                  Icon: <Description />,
                  link: `/demolition-permit/${PENDING_ASSESSMENT_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_ASSESSMENT_STATUS,
                    sDemolitionPermit
                  ),
                },
                {
                  title: "Applications Under Scrutiny",
                  visible: GetPermissionsRoles(
                    APPLICATIONS_UNDER_SCRUTINY,
                    sDemolitionPermit
                  ),
                  children: [
                    {
                      title: "Unscheduled",
                      link: `/demolition-permit/${UNSCHEDULED_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNSCHEDULED_STATUS,
                        sDemolitionPermit
                      ),
                    },
                    {
                      title: "Pending Inspection",
                      link: `/demolition-permit/${UNDER_INSPECTION_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNDER_INSPECTION_STATUS,
                        sDemolitionPermit
                      ),
                    },
                    {
                      title: "Inspected",
                      link: `/demolition-permit/${INSPECTED_STATUS}`,
                      visible: GetPermissionsRoles(
                        INSPECTED_STATUS,
                        sDemolitionPermit
                      ),
                    },
                    {
                      title: "Reviewed",
                      link: `/demolition-permit/${REVIEWED_STATUS}`,
                      visible: GetPermissionsRoles(
                        REVIEWED_STATUS,
                        sDemolitionPermit
                      ),
                    },
                    {
                      title: "Pending BC Consideration",
                      link: `/demolition-permit/${RECOMMENDED_STATUS}`,
                      visible: GetPermissionsRoles(
                        RECOMMENDED_STATUS,
                        sDemolitionPermit
                      ),
                    },
                    // {
                    //   title: "Recommended",
                    //   link: `/demolition-permit/${RECOMMENDED_STATUS}`,
                    // },
                  ],
                  Icon: <Description />,
                },
                {
                  title: "Pending Decision",
                  Icon: <Description />,
                  link: `/demolition-permit/${PENDING_DECISION_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_DECISION_STATUS,
                    sDemolitionPermit
                  ),
                },
                {
                  title: "Approved Applications",
                  Icon: <Description />,
                  link: `/demolition-permit/${APPROVED_STATUS}`,
                  visible: GetPermissionsRoles(
                    APPROVED_STATUS,
                    sDemolitionPermit
                  ),
                },
                {
                  title: "Deferred Applications",
                  Icon: <Description />,
                  link: `/demolition-permit/${DEFERRED_STATUS}`,
                  visible: GetPermissionsRoles(
                    DEFERRED_STATUS,
                    sDemolitionPermit
                  ),
                },
                {
                  title: "Rejected  Applications",
                  Icon: <Description />,
                  link: `/demolition-permit/${REJECTED_STATUS}`,
                  visible: GetPermissionsRoles(
                    REJECTED_STATUS,
                    sDemolitionPermit
                  ),
                },
              ],
            },
            {
              title: "Paid Registers",
              Icon: <List />,
              link: `/demolition-permit/${PAID}`,
            },
          ],
        },
        {
          visible:
            GetPermissionsRoles(sRenovationPermit) &&
            !GetPermissions([CAN_PUBLIC]),
          title: "Renovation Permit",
          Icon: <Sell />,
          children: [
            {
              title: "Applications",
              Icon: <List />,
              children: [
                {
                  title: "Apply for Renovation Permit",
                  Icon: <Add />,
                  link: "/renovation-permit/create",
                  visible: GetPermissions([CAN_PUBLIC]),
                },
                {
                  title: "Pending Applications",
                  Icon: <Description />,
                  link: `/renovation-permit/${STATUS_SUBMITTED}`,
                  visible: GetPermissionsRoles(
                    STATUS_SUBMITTED,
                    sRenovationPermit
                  ),
                },
                {
                  title: "Sent Back Applications",
                  Icon: <Description />,
                  link: `/renovation-permit/${SENT_BACK_STATUS}`,
                  visible: GetPermissionsRoles(
                    SENT_BACK_STATUS,
                    sRenovationPermit
                  ),
                },

                {
                  title: "Pending Assessment",
                  Icon: <Description />,
                  link: `/renovation-permit/${PENDING_ASSESSMENT_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_ASSESSMENT_STATUS,
                    sRenovationPermit
                  ),
                },

                {
                  title: "Applications Under Scrutiny",
                  visible: GetPermissionsRoles(
                    APPLICATIONS_UNDER_SCRUTINY,
                    sRenovationPermit
                  ),
                  children: [
                    {
                      title: "Unscheduled",
                      link: `/renovation-permit/${UNSCHEDULED_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNSCHEDULED_STATUS,
                        sRenovationPermit
                      ),
                    },
                    {
                      title: "Pending Inspection",
                      link: `/renovation-permit/${UNDER_INSPECTION_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNDER_INSPECTION_STATUS,
                        sRenovationPermit
                      ),
                    },
                    {
                      title: "Inspected",
                      link: `/renovation-permit/${INSPECTED_STATUS}`,
                      visible: GetPermissionsRoles(
                        INSPECTED_STATUS,
                        sRenovationPermit
                      ),
                    },
                    {
                      title: "Reviewed",
                      link: `/renovation-permit/${REVIEWED_STATUS}`,
                      visible: GetPermissionsRoles(
                        REVIEWED_STATUS,
                        sRenovationPermit
                      ),
                    },
                    {
                      title: "Pending BC Consideration",
                      link: `/renovation-permit/${RECOMMENDED_STATUS}`,
                      visible: GetPermissionsRoles(
                        RECOMMENDED_STATUS,
                        sRenovationPermit
                      ),
                    },

                    // {
                    //   title: "Recommended",
                    //   link: `/excavation-permit/${RECOMMENDED_STATUS}`,
                    // },
                  ],
                  Icon: <Description />,
                },

                {
                  title: "Pending Decision",
                  Icon: <Description />,
                  link: `/renovation-permit/${PENDING_DECISION_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_DECISION_STATUS,
                    sRenovationPermit
                  ),
                },
                {
                  title: "Approved Applications",
                  Icon: <Description />,
                  link: `/renovation-permit/${APPROVED_STATUS}`,
                  visible: GetPermissionsRoles(
                    APPROVED_STATUS,
                    sRenovationPermit
                  ),
                },
                {
                  title: "Deferred Applications",
                  Icon: <Description />,
                  link: `/renovation-permit/${DEFERRED_STATUS}`,
                  visible: GetPermissionsRoles(
                    DEFERRED_STATUS,
                    sRenovationPermit
                  ),
                },
                {
                  title: "Rejected Applications",
                  Icon: <Description />,
                  link: `/renovation-permit/${REJECTED_STATUS}`,
                  visible: GetPermissionsRoles(
                    REJECTED_STATUS,
                    sRenovationPermit
                  ),
                },
              ],
            },

            {
              title: "Paid Registers",
              Icon: <List />,
              link: `/renovation-permit/${PAID}`,
            },
          ],
        },
        {
          visible:
            GetPermissionsRoles(sExcavationPermit) &&
            !GetPermissions([CAN_PUBLIC]),
          title: "Excavation Permit",
          Icon: <Sell />,
          children: [
            {
              title: "Applications",
              Icon: <List />,

              children: [
                {
                  title: "Apply for Excavation Permit",
                  Icon: <Add />,
                  link: "/excavation-permit/create",
                  visible: GetPermissions([CAN_PUBLIC]),
                },
                {
                  title: "Pending Applications",
                  Icon: <Description />,
                  link: `/excavation-permit/${PENDING_STATUS}`,
                  visible: GetPermissionsRoles(
                    STATUS_SUBMITTED,
                    sExcavationPermit
                  ),
                },
                {
                  title: "Sent Back Applications",
                  Icon: <Description />,
                  link: `/excavation-permit/${SENT_BACK_STATUS}`,
                  visible: GetPermissionsRoles(
                    SENT_BACK_STATUS,
                    sExcavationPermit
                  ),
                },
                {
                  title: "Pending Assessment",
                  Icon: <Description />,
                  link: `/excavation-permit/${PENDING_ASSESSMENT_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_ASSESSMENT_STATUS,
                    sExcavationPermit
                  ),
                },
                {
                  title: "Applications Under Scrutiny",
                  visible: GetPermissionsRoles(
                    APPLICATIONS_UNDER_SCRUTINY,
                    sExcavationPermit
                  ),
                  children: [
                    {
                      title: "Unscheduled",
                      link: `/excavation-permit/${UNSCHEDULED_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNSCHEDULED_STATUS,
                        sExcavationPermit
                      ),
                    },
                    {
                      title: "Pending Inspection",
                      link: `/excavation-permit/${UNDER_INSPECTION_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNDER_INSPECTION_STATUS,
                        sExcavationPermit
                      ),
                    },
                    {
                      title: "Inspected",
                      link: `/excavation-permit/${INSPECTED_STATUS}`,
                      visible: GetPermissionsRoles(
                        INSPECTED_STATUS,
                        sExcavationPermit
                      ),
                    },
                    {
                      title: "Reviewed",
                      link: `/excavation-permit/${REVIEWED_STATUS}`,
                      visible: GetPermissionsRoles(
                        REVIEWED_STATUS,
                        sExcavationPermit
                      ),
                    },
                    {
                      title: "Pending BC Consideration",
                      link: `/excavation-permit/${RECOMMENDED_STATUS}`,
                      visible: GetPermissionsRoles(
                        RECOMMENDED_STATUS,
                        sExcavationPermit
                      ),
                    },

                    // {
                    //   title: "Recommended",
                    //   link: `/excavation-permit/${RECOMMENDED_STATUS}`,
                    // },
                  ],
                  Icon: <Description />,
                },
                {
                  title: "Pending Decision",
                  Icon: <Description />,
                  link: `/excavation-permit/${PENDING_DECISION_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_DECISION_STATUS,
                    sExcavationPermit
                  ),
                },
                {
                  title: "Approved Applications",
                  Icon: <Description />,
                  link: `/excavation-permit/${APPROVED_STATUS}`,
                  visible: GetPermissionsRoles(
                    APPROVED_STATUS,
                    sExcavationPermit
                  ),
                },
                {
                  title: "Deferred Applications",
                  Icon: <Description />,
                  link: `/excavation-permit/${DEFERRED_STATUS}`,
                  visible: GetPermissionsRoles(
                    DEFERRED_STATUS,
                    sExcavationPermit
                  ),
                },
                {
                  title: "Rejected  Applications",
                  Icon: <Description />,
                  link: `/excavation-permit/${REJECTED_STATUS}`,
                  visible: GetPermissionsRoles(
                    REJECTED_STATUS,
                    sExcavationPermit
                  ),
                },
              ],
            },

            {
              title: "Paid Registers",
              Icon: <List />,
              link: `/excavation-permit/${PAID}`,
            },
          ],
        },
        {
          visible:
            GetPermissionsRoles(sHoardingPermit) &&
            !GetPermissions([CAN_PUBLIC]),
          title: "Hoarding Permit",
          Icon: <Sell />,
          children: [
            {
              title: "Applications",
              Icon: <List />,

              children: [
                {
                  title: "Apply for Hoarding Permit",
                  Icon: <Add />,
                  link: "/hoarding-permit/create",
                  visible: GetPermissions([CAN_PUBLIC]),
                },
                {
                  title: "Pending Applications",
                  Icon: <Description />,
                  link: `/hoarding-permit/${PENDING_STATUS}`,
                  visible: GetPermissionsRoles(
                    STATUS_SUBMITTED,
                    sHoardingPermit
                  ),
                },
                {
                  title: "Sent Back Applications",
                  Icon: <Description />,
                  link: `/hoarding-permit/${SENT_BACK_STATUS}`,
                  visible: GetPermissionsRoles(
                    SENT_BACK_STATUS,
                    sHoardingPermit
                  ),
                },

                {
                  title: "Pending Assessment",
                  Icon: <Description />,
                  link: `/hoarding-permit/${PENDING_ASSESSMENT_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_ASSESSMENT_STATUS,
                    sHoardingPermit
                  ),
                },
                {
                  title: "Applications Under Scrutiny",
                  visible: GetPermissionsRoles(
                    APPLICATIONS_UNDER_SCRUTINY,
                    sHoardingPermit
                  ),
                  children: [
                    {
                      title: "Unscheduled",
                      link: `/hoarding-permit/${UNSCHEDULED_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNSCHEDULED_STATUS,
                        sHoardingPermit
                      ),
                    },
                    {
                      title: "Pending Inspection",
                      link: `/hoarding-permit/${UNDER_INSPECTION_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNDER_INSPECTION_STATUS,
                        sHoardingPermit
                      ),
                    },
                    {
                      title: "Inspected",
                      link: `/hoarding-permit/${INSPECTED_STATUS}`,
                      visible: GetPermissionsRoles(
                        INSPECTED_STATUS,
                        sHoardingPermit
                      ),
                    },
                    {
                      title: "Reviewed",
                      link: `/hoarding-permit/${REVIEWED_STATUS}`,
                      visible: GetPermissionsRoles(
                        REVIEWED_STATUS,
                        sHoardingPermit
                      ),
                    },
                    {
                      title: "Pending BC Consideration",
                      link: `/hoarding-permit/${RECOMMENDED_STATUS}`,
                      visible: GetPermissionsRoles(
                        RECOMMENDED_STATUS,
                        sHoardingPermit
                      ),
                    },
                    // {
                    //   title: "Recommended",
                    //   link: `/hoarding-permit/${RECOMMENDED_STATUS}`,
                    // },
                  ],
                  Icon: <Description />,
                },
                {
                  title: "Pending Decision",
                  Icon: <Description />,
                  link: `/hoarding-permit/${PENDING_DECISION_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_DECISION_STATUS,
                    sHoardingPermit
                  ),
                },
                {
                  title: "Approved Applications",
                  Icon: <Description />,
                  link: `/hoarding-permit/${APPROVED_STATUS}`,
                  visible: GetPermissionsRoles(
                    APPROVED_STATUS,
                    sHoardingPermit
                  ),
                },
                {
                  title: "Deferred Applications",
                  Icon: <Description />,
                  link: `/hoarding-permit/${DEFERRED_STATUS}`,
                  visible: GetPermissionsRoles(
                    DEFERRED_STATUS,
                    sHoardingPermit
                  ),
                },
                {
                  title: "Rejected  Applications",
                  Icon: <Description />,
                  link: `/hoarding-permit/${REJECTED_STATUS}`,
                  visible: GetPermissionsRoles(
                    REJECTED_STATUS,
                    sHoardingPermit
                  ),
                },
              ],
            },

            {
              title: "Paid Registers",
              Icon: <List />,
              link: `/hoarding-permit/${PAID}`,
            },
          ],
        },

        {
          visible: GetPermissionsRoles(sChainlinkPermit),
          title: "Chainlink Permit",
          Icon: <Sell />,
          children: [
            {
              title: "Applications",
              Icon: <List />,

              children: [
                {
                  title: "Apply for Chainlink Permit",
                  Icon: <Add />,
                  link: "/chainlink-permit/create",
                  visible: GetPermissions([CAN_PUBLIC]),
                },
                {
                  title: "Pending Applications",
                  Icon: <Description />,
                  link: `/chainlink-permit/${PENDING_STATUS}`,
                  visible: GetPermissionsRoles(
                    STATUS_SUBMITTED,
                    sChainlinkPermit
                  ),
                },
                {
                  title: "Sent Back Applications",
                  Icon: <Description />,
                  link: `/chainlink-permit/${SENT_BACK_STATUS}`,
                  visible: GetPermissionsRoles(
                    SENT_BACK_STATUS,
                    sChainlinkPermit
                  ),
                },
                {
                  title: "Pending Assessment",
                  Icon: <Description />,
                  link: `/chainlink-permit/${PENDING_ASSESSMENT_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_ASSESSMENT_STATUS,
                    sChainlinkPermit
                  ),
                },
                {
                  title: "Applications Under Scrutiny",
                  visible: GetPermissionsRoles(
                    APPLICATIONS_UNDER_SCRUTINY,
                    sChainlinkPermit
                  ),
                  children: [
                    {
                      title: "Unscheduled",
                      link: `/chainlink-permit/${UNSCHEDULED_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNSCHEDULED_STATUS,
                        sChainlinkPermit
                      ),
                    },
                    {
                      title: "Pending Inspection",
                      link: `/chainlink-permit/${UNDER_INSPECTION_STATUS}`,
                      visible: GetPermissionsRoles(
                        UNDER_INSPECTION_STATUS,
                        sChainlinkPermit
                      ),
                    },
                    {
                      title: "Inspected",
                      link: `/chainlink-permit/${INSPECTED_STATUS}`,
                      visible: GetPermissionsRoles(
                        INSPECTED_STATUS,
                        sChainlinkPermit
                      ),
                    },
                    {
                      title: "Reviewed",
                      link: `/chainlink-permit/${REVIEWED_STATUS}`,
                      visible: GetPermissionsRoles(
                        REVIEWED_STATUS,
                        sChainlinkPermit
                      ),
                    },
                    {
                      title: "Pending PPC Consideration",
                      link: `/chainlink-permit/${RECOMMENDED_STATUS}`,
                      visible: GetPermissionsRoles(
                        RECOMMENDED_STATUS,
                        sChainlinkPermit
                      ),
                    },
                    // {
                    //   title: "Recommended",
                    //   link: `/chainlink-permit/${RECOMMENDED_STATUS}`,
                    // },
                  ],
                  Icon: <Description />,
                },
                {
                  title: "Pending Decision",
                  Icon: <Description />,
                  link: `/chainlink-permit/${PENDING_DECISION_STATUS}`,
                  visible: GetPermissionsRoles(
                    PENDING_DECISION_STATUS,
                    sChainlinkPermit
                  ),
                },
                {
                  title: "Approved Applications",
                  Icon: <Description />,
                  link: `/chainlink-permit/${APPROVED_STATUS}`,
                  visible: GetPermissionsRoles(
                    APPROVED_STATUS,
                    sChainlinkPermit
                  ),
                },
                {
                  title: "Deferred Applications",
                  Icon: <Description />,
                  link: `/chainlink-permit/${DEFERRED_STATUS}`,
                  visible: GetPermissionsRoles(
                    DEFERRED_STATUS,
                    sChainlinkPermit
                  ),
                },
                {
                  title: "Rejected  Applications",
                  Icon: <Description />,
                  link: `/chainlink-permit/${REJECTED_STATUS}`,
                  visible: GetPermissionsRoles(
                    REJECTED_STATUS,
                    sChainlinkPermit
                  ),
                },
              ],
            },

            {
              title: "Paid Registers",
              Icon: <List />,
              link: `/chainlink-permit/${PAID}`,
            },
          ],
        },
    //   ],
    // },

    /* Property Valuation */
    {
      title: sPropertyValuation,
      Icon: <LocationCity />,
      visible: false,
      // (GetPermissionsRoles(sPropertyValuation) &&
      //   !IsMarketAdmin() &&
      //   !HasUserType([PUBLIC_APP_USER])) ||
      // IsAuditAuthority(),
      children: [
        {
          title: `${sNew} ${sPropertyValuation}`,
          link: "/property-valuation/create",
          // visible: GetPermissionsRoles("newPV"),
          visible: !IsAuditAuthority(),
          Icon: <Add />,
        },
        {
          title: sPendingRegister,
          link: `/property-valuation/${PROPERTY_VALUATION_STATUS_PENDING}`,
          visible: GetPermissionsRoles("pendingPV"),
          Icon: <Description />,
        },
        {
          title: sApprovedRegister,
          link: `/property-valuation/${PROPERTY_VALUATION_STATUS_APPROVED}`,
          visible: GetPermissionsRoles("approvedPV"),
          Icon: <Description />,
        },

        {
          title: sDraftRegister,
          link: `/property-valuation/${PROPERTY_VALUATION_STATUS_DRAFTED}`,
          Icon: <Description />,
        },
        {
          title: sCertifiedRegister,
          link: `/property-valuation/${PROPERTY_VALUATION_STATUS_CERTIFIED}`,
          Icon: <Description />,
        },
        {
          title: sDefferedRegister,
          link: `/property-valuation/${STATUS_DEFFERED}`,
          visible: GetPermissionsRoles("defferedPV"),
          Icon: <Description />,
        },
        {
          title: sRejectedRegister,
          link: `/property-valuation/${STATUS_REJECTED}`,
          visible: GetPermissionsRoles("defferedPV"),
          Icon: <Description />,
        },
        {
          title: "Objections",
          Icon: <ThumbDown />,
          children: [
            {
              title: "Objections Register",
              link: `/property-valuation-objections/${STATUS_SUBMITTED}`,
              Icon: <Description />,
            },
            {
              title: "Approved Objections",
              link: `/property-valuation-objections/${STATUS_APPROVED_OBJECTIONS}`,
              Icon: <Description />,
            },
            {
              title: "Withdrawn Objections",
              link: `/property-valuation-objections/${STATUS_WITHDRAWN_OBJECTIONS}`,
              Icon: <Description />,
            },
            {
              title: "Rejected Objections",
              link: `/property-valuation-objections/${STATUS_REJECTED_OBJECTIONS}`,
              Icon: <Description />,
            },
            {
              title: "Awaiting Court Hearing",
              Icon: <Gavel />,
              children: [
                {
                  title: "Awaiting Court Hearing",
                  Icon: <Description />,
                  link: `/property-valuation-objections/${STATUS_AWAIT_COURT_HEARING}`,
                },
                {
                  title: "Objector Representative ",
                  Icon: <Description />,
                  link: "/property-valuation-objections/objector_representative",
                },
                {
                  title: "Hearing Schedule Change ",
                  Icon: <Description />,
                  link: "/property-valuation-objections/hearing_schedule_change",
                },
                {
                  title: "Court Proceedings",
                  Icon: <Description />,
                  link: "/property-valuation-objections/add_court_proceedings",
                },
              ],
            },
          ],
        },
      ],
    },

    // ONTR
    // {
    //   title: sONTR,
    //   visible:
    //     GetPermissions([
    //       ...mainPermissions,
    //       ...developmentControlPermissions,
    //     ]) ||
    //     GetPermissionsRoles(sONTR) ||
    //     IsAuditAuthority(),
    //   Icon: <Payment />,
    //   children: [
    //     {
    //       title: "Apply for ONTR Payment",
    //       Icon: <Add />,
    //       visible: !IsAuditAuthority() && !GetPermissions([CAN_PUBLIC]),
    //       link: "/ontrs/create",
    //     },
    //     {
    //       title: "Register",
    //       Icon: <Description />,
    //       link: `/ontrs/${ONTR_ACTIVE_STATUS}`,
    //     },
    //     {
    //       title: sDeactivatedRegister,
    //       link: `/ontrs/${STATUS_DEACTIVATED}`,
    //       Icon: <DeactivateButton />,
    //     },
    //   ],
    // },

    /* Payments */
    {
      title: sPayments,
      visible: !GetPermissions([CAN_PUBLIC]),
      // hidden: GetPermissions([CAN_PROFESSIONAL]),
      // visible: !HasUserType([MIS_AUTHORITY]),
      Icon: <Payment />,
      children: [
        {
          title: "Register Payment",
          Icon: <AddCard />,
          visible: !IsAuditAuthority(),
          link: "/register-payment",
        },
        {
          title: "PRNs",
          link: `/prns/`,
          Icon: <Description />,
        },
        {
          title: sPayments,
          link: `/payments/`,
          Icon: <Description />,
        },
        {
          title: "Check PRN Status",
          Icon: <QuestionMark />,
          click: setOpenPrn,
        },
      ],
    },

    // Tin Registration
    {
      title: "TIN Registration",
      visible: false,
      // GetPermissions([CAN_ADMIN, CAN_MUNICIPALITY]) || IsAuditAuthority(),
      Icon: <KeyOutlined />,
      children: [
        {
          title: "Create TIN",
          Icon: <Edit />,
          visible: !IsAuditAuthority(),
          link: "/instant-tin/create",
        },
        {
          title: "Registered TINs",
          visible: GetPermissionsRoles("Registered TINs"),
          Icon: <Description />,
          link: "/instant-tin/register",
        },
      ],
    },
    {
      title: "Check Application Status",
      Icon: <QuestionMark />,
      click: () => setOpenPermit(STATUS_DEVELOPMENT_CONTROL),
    },
    // SERVICE APPLICATION
    {
      title: "Track Application Status",
      visible: false,
      // visible: !HasUserType([MIS_AUTHORITY]),
      // hidden: GetPermissions([CAN_PROFESSIONAL]),
      Icon: <LocationOn />,
      children: [
        {
          title: sRevenueSources,
          Icon: <VerifiedUserSharp />,
          visible: false,
          children: [
            {
              title: "Check Application Status",
              Icon: <QuestionMark />,
              click: () => setOpenPermit(STATUS_REVENUE_SOURCE),
            },
          ],
        },
        {
          title: "Service Application",
          Icon: <RoomService />,
          visible: false,
          children: [
            {
              title: "Check Application Status",
              Icon: <QuestionMark />,
              click: () => setOpenPermit(STATUS_SERVICE_APPLICATION),
            },
          ],
        },
        {
          title: "Enquiries",
          Icon: <ContactSupport />,
          visible: false,
          children: [
            {
              title: "Check Application Status",
              Icon: <QuestionMark />,
              click: () => setOpenPermit(STATUS_GRIEVANCE),
            },
          ],
        },
      ],
    },

    // Amendments
    {
      title: "Amendments",
      visible: false,
      // GetPermissions([...mainPermissions, CAN_PUBLIC]) || IsAuditAuthority(),
      Icon: <SettingOutlined />,
      children: [
        {
          title: "Apply for amendment",
          link: "/amendments/request",
          visible: !IsAuditAuthority(),
          Icon: <Add />,
        },
        {
          title: "Pending amendments",
          link: "/amendments/register/pending",
          Icon: <FaFileAlt />,
        },
        {
          title: "Approved amendments",
          link: "/amendments/register/approved",
          Icon: <FaCheck />,
        },
        {
          title: "Rejected amendments",
          link: "/amendments/register/rejected",
          Icon: <FaBan />,
        },
      ],
    },

    /** Reassessments */
    {
      title: "Reassessments",
      visible: false,
      // visible: !HasUserType([MIS_AUTHORITY]),
      // hidden: GetPermissions([
      //   CAN_PROFESSIONAL,
      //   CAN_PHYSICAL_PLANNING,
      //   CAN_PHYSICAL_PLANNING_DIVISION,
      // ]),
      Icon: <FaCashRegister />,
      children: [
        {
          title: "Local Hotel Tax",
          Icon: <Hotel />,
          children: [
            {
              title: "Pending Reassessments",
              link: "/lht-reassessments/pending",
              Icon: <FaFileAlt />,
            },
            {
              title: "Approved Reassessments",
              link: "/lht-reassessments/approved",
              Icon: <FaCheck />,
            },
            {
              title: "Rejected Reassessments",
              link: "/lht-reassessments/rejected",
              Icon: <FaBan />,
            },
          ],
        },
        {
          title: "Local Service Tax",
          Icon: <RoomService />,
          children: [
            {
              title: "Pending Reassessments",
              link: "/lst-reassessments/pending",
              Icon: <FaFileAlt />,
            },
            {
              title: "Approved Reassessments",
              link: "/lst-reassessments/approved",
              Icon: <FaCheck />,
            },
            {
              title: "Rejected Reassessments",
              link: "/lst-reassessments/rejected",
              Icon: <FaBan />,
            },
          ],
        },
        {
          title: "Other Revenue Sources",
          Icon: <RoomService />,
          children: [
            {
              title: "Apply for Reassessment",
              link: "/reassessments/request",
              visible: !IsAuditAuthority(),
              Icon: <Add />,
            },
            {
              title: "Pending Reassessments",
              link: "/reassessments/register/pending",
              Icon: <FaFileAlt />,
            },
            {
              title: "Approved Reassessments",
              link: "/reassessments/register/approved",
              Icon: <FaCheck />,
            },
            {
              title: "Rejected Reassessments",
              link: "/reassessments/register/rejected",
              Icon: <FaBan />,
            },
          ],
        },
      ],
    },

    // Objection & Appeals
    {
      title: "Objection Registration",
      visible: false,
      // GetPermissions([...mainPermissions, CAN_PUBLIC]) || IsAuditAuthority(),
      Icon: <DataObject />,
      children: [
        {
          title: "Apply for Objection",
          link: "/objections/request",
          visible: !IsAuditAuthority(),
          Icon: <Add />,
        },
        {
          title: "Pending objections",
          link: "/objections/register/pending",
          Icon: <FaFileAlt />,
          visible: GetPermissions(mainPermissions),
        },
        {
          title: "Approved objections",
          link: "/objections/register/approved",
          Icon: <FaCheck />,
          visible: GetPermissions(mainPermissions),
        },
        {
          title: "Rejected objections",
          link: "/objections/register/rejected",
          Icon: <FaBan />,
          visible: GetPermissions(mainPermissions),
        },
      ],
    },

    /** Business transfers */
    {
      title: "Business Transfers",
      visible: false,
      // GetPermissions([...mainPermissions, CAN_PUBLIC]) || IsAuditAuthority(),
      Icon: <FaTruckMoving />,
      children: [
        {
          title: "Apply for Business Transfer",
          link: "/business-transfers/request",
          visible: !IsAuditAuthority(),
          Icon: <Add />,
        },
        {
          title: "Pending business transfers",
          link: "/business-transfers/register/pending",
          Icon: <FaFileAlt />,
        },
        {
          title: "Approved business transfers",
          link: "/business-transfers/register/approved",
          Icon: <FaCheck />,
        },
        {
          title: "Rejected business transfers",
          link: "/business-transfers/register/rejected",
          Icon: <FaBan />,
        },
      ],
    },

    // Business Deactivation
    {
      title: "Business Deactivation",
      visible: false,
      // GetPermissions([...mainPermissions, CAN_PUBLIC]) || IsAuditAuthority(),
      Icon: <DesktopAccessDisabled />,
      children: [
        {
          title: "Apply for Deactivation",
          link: "/business-deactivation/request",
          visible: !IsAuditAuthority(),
          Icon: <Add />,
        },
        {
          title: "Pending Register",
          link: "/business-deactivation/register/pending",
          Icon: <FaFileAlt />,
          visible: GetPermissions(mainPermissions),
        },
        {
          title: "Approved Register",
          link: "/business-deactivation/register/approved",
          Icon: <FaCheck />,
          visible: GetPermissions(mainPermissions),
        },
        {
          title: "Rejected Register",
          link: "/business-deactivation/register/rejected",
          Icon: <FaBan />,
          visible: GetPermissions(mainPermissions),
        },
      ],
    },

    // Service Desk
    {
      title: "Enquiries",
      link: "/",
      Icon: <ContactSupport />,
      // visible: !HasUserType([MIS_AUTHORITY]),
      visible: false,
      children: [
        {
          title: "Start Enquiry",
          link: "/grievances/create",
          Icon: <Add />,
        },
        {
          title: "Enquiries",
          Icon: <Description />,
          link: "/grievances/all-enquiries",
          visible: GetPermissions(mainPermissions),
        },
        {
          title: "My Enquiries",
          Icon: <Description />,
          visible: GetPermissions([CAN_PUBLIC]),
          link: "/grievances/user-grievances",
        },
        {
          title: "Pending",
          Icon: <Description />,
          visible: GetPermissionsRoles("Pending"),
          link: "/grievances/100",
        },
        {
          title: "Resolved",
          Icon: <Description />,
          visible: GetPermissionsRoles("Resolved"),
          link: "/grievances/101",
        },

        {
          title: "Overdue",
          Icon: <Description />,
          visible: GetPermissionsRoles("Overdue"),
          link: "/grievances/query-assignments/overdue",
        },
      ],
    },

    // Tax Payers Ledger
    {
      title: "Tax Payers",
      Icon: <SupervisorAccount />,
      visible: false,
      // GetPermissions([
      //   CAN_ADMIN,
      //   CAN_MUNICIPALITY,
      //   CAN_PARISH,
      //   APP_CAN_APPROVE,
      // ]) || IsAuditAuthority(),
      children: [
        {
          title: "Taxpayer Register",
          Icon: <Description />,
          link: "/taxpayer-register",
        },
        {
          title: "Ledgers",
          Icon: <Description />,
          link: "/general-ledger",
        },
        {
          title: "Taxpayer Ledger",
          Icon: <Description />,
          link: "/ledgers",
        },
      ],
    },

    // Audit Activity
    {
      title: "Audit Activity",
      visible: false,
      // visible: GetPermissions([CAN_ADMIN]) || IsAuditAuthority(),
      Icon: <AssignmentTurnedIn />,
      link: "/audit/activity",
    },

    // User Manual
    {
      title: "User Manual",
      visible: false,
      // !GetPermissions([CAN_PUBLIC]) ||
      // IsAuditAuthority() ||
      // !HasUserType([MIS_AUTHORITY]),
      Icon: <FaWrench />,
      children: [
        {
          title: "User Manual Guide",
          visible: !GetPermissions([CAN_PUBLIC]),
          Icon: <Description />,
          click: () => window.open(userGuideInternal, "_blank"),
        },
        {
          title: "POS Machine Manual",
          visible: !GetPermissions([CAN_PUBLIC]),
          Icon: <Description />,
          click: () => window.open(posUserGuide, "_blank"),
        },
      ],
    },
  ];
  return menu;
};

export const LeftMenu = () => {
  return (
    <Menu
      className="font13 leftmenu radius6"
      mode="inline"
      defaultSelectedKeys={["home"]}
      // theme="dark"
      style={{
        border: "none",
        // backgroundColor: configColor,
        color: configColor,
      }}
    >
      {RecursiveMenu(LeftMenuItems())}
    </Menu>
  );
};
