import { createTheme } from "@mui/material";

// colors
const primaryColor = "#09723f";
const yellow = "#ffb566";
const dimWhite = "#f2f2f2";
const configColor = "#09723f";
const configSecondary = "#747474";
const configTertiary = "#f9dd8b";
// const configTertiary = "#f5cf55";

// const configColor = "#AB6230";

const theme = createTheme({
  palette: {
    // mode: "dark",
    primary: {
      main: primaryColor,
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },

  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: 13,
          borderRadius: 8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
  },
});

const antdTheme = {
  token: {
    colorPrimary: configColor,
    // colorSecondary:
  },
};

export {
  theme,
  antdTheme,
  primaryColor,
  dimWhite,
  yellow,
  configColor,
  configSecondary,
  configTertiary,
};
