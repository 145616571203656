import React, { useState } from "react";
import { Button, Modal } from "antd";

export const InfoModal = ({
  label,
  title,
  children,
  buttonProps,
  showOnLoad = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(showOnLoad || false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        type="primary"
        className="font12"
        onClick={showModal}
        {...buttonProps}
      >
        {label}
      </Button>
      <Modal
        title={title}
        open={isModalOpen}
        onOk={handleOk}
        footer={
          <Button type="primary" onClick={handleOk}>
            Continue
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        {children}
      </Modal>
    </>
  );
};
