import React, { useEffect, useState } from "react";
import FormInput from "../../../common/FormInput";
import useForm from "../../../../hooks/common/useForm";
import SelectInput from "../../../common/SelectInput";
import {
  ALL_FEES_API,
  API_GENERATE_BILL,
  FEES_API,
  FETCH_PLANNING_RATES_API,
  OCCUPATIONAL_PERMIT_API,
  PERMIT_FEES_API,
  PERMIT_FEES_CHARGED_API,
  PLANNING_RATES_API,
} from "../../../../constants/api";
import useGenericGet from "../../../../hooks/common/useGenericGet";
import { Visibility } from "../../../common/Visibility";
import useGenericSet from "../../../../hooks/common/useGenericSet";
import { NO, YES } from "../../../../constants/webapp";

const OccupationalPermitAssessment = ({ tax, tax_id, formId, proceed, period }) => {
  const { form, updateFormValue, updateFormValues, onChange, setForm } =
    useForm();

  const { data: occupational_permit, loadData: getOccupationalPermit } =
    useGenericGet();
  const { data: rate, loadData: getRates } = useGenericGet();
  const { data: permit_fees, loadData: getPermitFees } = useGenericGet();
  const [ratingZone, setRatingZone] = useState("");
  const [seriesAmount, setSeriesAmount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [vat, setVat] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [seriesGrossArea, setSeriesGrossArea] = useState(0);
  const { uploadData, error, success } = useGenericSet();

  const userData = JSON.parse(localStorage.getItem("userData"));

  const options = [
    {
      id: NO,
      name: "No",
    },
    {
      id: YES,
      name: "Yes",
    },
  ];

  const [data, setData] = useState([
    {
      id: 0,
      permit_fee: 0,
      quantity: 0,
      rate: 0,
      percentage: 0,
      amount: 0,
      permit_id: tax_id,
    },
  ]);

  useEffect(() => {
    getOccupationalPermit({
      api: OCCUPATIONAL_PERMIT_API,
      id: tax_id,
    });
  }, []);

  useEffect(() => {
    if (occupational_permit) {
      getPermitFees({
        api: ALL_FEES_API,
        params: {
          municipality: occupational_permit?.municipality_name,
          permit_type: "occupation-permit",
        },
      });
    }
  }, [occupational_permit]);

  useEffect(() => {
    if (occupational_permit) {
      getRates({
        api: FETCH_PLANNING_RATES_API,
        params: {
          building_classification:
            occupational_permit?.classification_of_building,
            administration: form?.administration,           
            // (userData?.role_details?.can_physical_planning_division === 1 || userData?.role_details?.can_physical_planning_division === true) ?
            // 4 : occupational_permit?.municipality_administration,
            type_of_application: "occupation_permit"
        },
      });
    }
  }, [occupational_permit, form?.administration]);

  useEffect(() => {
    let rating_zone = "";
    if (occupational_permit) {
      switch (occupational_permit.municipality_administration) {
        case 1:
          rating_zone = "City";
          setRatingZone(rating_zone);
          break;
        case 2:
          rating_zone = "Municipal Council";
          setRatingZone(rating_zone);
          break;
        case 3:
          rating_zone = "District";
          setRatingZone(rating_zone);
          break;
        default:
          rating_zone = "";
          break;
      }
    }

    if ((userData?.role_details?.can_physical_planning_division === 1 ||
      userData?.role_details?.can_physical_planning_division === true) 
      && rating_zone == "District") {
     rating_zone = "Town Council";
     setRatingZone(rating_zone);
   }

  }, [occupational_permit]);

  useEffect(() => {
    if (success) {
      window.location.reload(true)
    }
  }, [success])

  const onSubmit = (event) => {
    event.preventDefault();
    
    uploadData({
      api: API_GENERATE_BILL,
      params : {
        tax: "occupation_permit",
        period: period,
        tax_id: tax_id,
        other_fees_select: 0,
        other_fees: Math.round(form?.amount_any_other_fee) || 0,
        other_fees_spec: form?.specify || "null",
        // series_gross_area: form?.gross_floor_area || 0,
        series_fee: Math.round(seriesAmount),
        scrutiny_area: 0,
        scrutiny_fee: 0,
        building_control_area: form?.building_control_area || 0,
        building_control_fee: Math.round(form.building_control_fee_amount) || 0,
        boundary_wall_amount: 0,
        boundary_wall_area: 0,
        telecom_mast_area: form?.telecom_mast_area || 0,
        telecom_mast_amount: Math.round(form?.telecom_mast_fee_amount) || 0,
        fuel_station_area: form?.fuel_station_area || 0,
        fuel_station_amount: Math.round(form?.fuel_station_fee_amount) || 0,
        permit_fee: Math.round(subTotal),
        fees_charged: data,
        amount: Math.round(totalAmount),
        series_gross_area: seriesGrossArea,
        duration: form?.duration
      }
    })
  }

  // const onSubmit = (event) => {
  //   event.preventDefault();
  //   proceed({ amount: totalAmount });

  //   uploadData({
  //     api: OCCUPATIONAL_PERMIT_API,
  //     id: tax_id,
  //     params: {
  //       duration: form.duration,
  //       // scrutiny_fee: form.scrutiny_fee_amount,
  //       // scrutiny_area: form?.scrutiny_area,
  //       building_control_fee: form.building_control_fee_amount,
  //       building_control_area: form?.building_control_area,
  //       // boudary_wall_amount: form?.boundary_wall_fee_amount,
  //       // boundary_wall_area: form?.boundary_wall_area,
  //       fuel_station_amount: form?.fuel_station_fee_amount,
  //       fuel_station_area: form?.fuel_station_area,
  //       telecom_mast_amount: form?.telecom_mast_fee_amount,
  //       telecom_mast_area: form?.telecom_mast_area,
  //       series_gross_area: form.gross_floor_area,
  //       occupation_permit_fee: form.subTotal,
  //       series_fee: seriesAmount,
  //       other_fees_spec: form.specify,
  //       other_fees: form.amount_any_other_fee,
  //       amount: totalAmount
  //     },
  //   });

  //   uploadData({
  //     api: PERMIT_FEES_CHARGED_API,
  //     params: {
  //       fees_charged: data,
  //     },
  //   });
  // };

  const handlePermitTypeChange = (value, key, index, obj) => {
    const element = obj.percentage;

    const updatedData = data?.map((obj, key) => {
      if (key === index) {
        return { ...obj, percentage: Number(element), permit_fee: value };
      }
      return obj;
    });

    setData(updatedData);
  };

  const handleQuantityChange = (event, key, index, rate) => {
    let value = event.target.value;

    const updatedData = data?.map((obj, key) => {
      if (key === index) {
        return {
          ...obj,
          amount: Number(value) * (Number(obj.percentage) / 100) * Number(rate),
          quantity: value,
        };
      }
      return obj;
    });
    setData(updatedData);
  };

  const handleFee = (event) => {
    event.preventDefault();

    var row = {
      permit_fee: 0,
      quantity: 0,
      rate: 0,
      percentage: 0,
      amount: 0,
      permit_id: tax_id,
    };

    setData([...data, row]);
  };

  const deleteFee = (event, index) => {
    event.preventDefault();
    let fees_data = [...data];
    fees_data.splice(index, 1);
    setData([...fees_data]);
  };

  const seriesOccupationalCalculationHandler = (event) => {
    let series_gross_area = event.target.value;
    let numBlocks =
      parseInt(occupational_permit?.num_blocks_fully_completed) +
      parseInt(occupational_permit?.num_blocks_partially_completed);
    let series_rate = rate?.rate;
    let series_area_calculated = 0;

    // calulate series fees
    if (numBlocks > 1) {
      series_area_calculated += Number(series_rate) * Number(series_gross_area);
    }
    numBlocks -= 1;

    if (numBlocks > 0) {
      series_area_calculated +=
        Number(series_rate) *
        Number(series_gross_area) *
        0.9 *
        Math.min(numBlocks, 4);
      numBlocks -= 4;
      if (numBlocks > 0) {
        series_area_calculated +=
          Number(series_rate) *
          Number(series_gross_area) *
          0.85 *
          Math.min(numBlocks, 5);
        numBlocks -= 5;
        if (numBlocks > 0) {
          series_area_calculated +=
            Number(series_rate) *
            Number(series_gross_area) *
            0.75 *
            Math.min(numBlocks, 15);
          numBlocks -= 15;
          if (numBlocks > 0) {
            series_area_calculated +=
              Number(series_rate) * Number(series_gross_area) * 0.6 * numBlocks;
          }
        }
      }
    }

    if (series_area_calculated != 0) {
      setSeriesGrossArea(series_gross_area)
    }

    setSeriesAmount(series_area_calculated);
  };

  useEffect(() => {
    let total = 0;
    data?.map((item) => (total += parseFloat(item.amount)));

    let seriesAmt = seriesAmount ? parseFloat(seriesAmount) : 0;
    let other_fees = form.amount_any_other_fee
      ? parseFloat(form.amount_any_other_fee)
      : 0;
    let scrutiny_fees = form.scrutiny_fee_amount
      ? parseFloat(form.scrutiny_fee_amount)
      : 0;
    let building_control_fees = form.building_control_fee_amount
      ? parseFloat(form.building_control_fee_amount)
      : 0;
    let boundary_wall_fees = form.boundary_wall_fee_amount
      ? parseFloat(form.boundary_wall_fee_amount)
      : 0;
    let fuel_station_fees = form.fuel_station_fee_amount
      ? parseFloat(form.fuel_station_fee_amount)
      : 0;
    let telecom_mast_fees = form.telecom_mast_fee_amount
      ? parseFloat(form.telecom_mast_fee_amount)
      : 0;

    setSubTotal(
      parseFloat(total) +
        seriesAmt +
        other_fees +
        // scrutiny_fees +
        building_control_fees +
        // boundary_wall_fees +
        fuel_station_fees +
        telecom_mast_fees
    );
  }, [
    seriesAmount,
    data,
    form.amount_any_other_fee,
    form.scrutiny_fee_amount,
    form.building_control_fee_amount,
    form.boundary_wall_fee_amount,
    form.fuel_station_fee_amount,
    form.telecom_mast_fee_amount,
  ]);

  useEffect(() => {
    setVat(0.18 * subTotal);
  }, [subTotal]);

  useEffect(() => {
    setTotalAmount(parseFloat(vat + subTotal));
  }, [subTotal, vat]);

  useEffect(() => {
    if (form?.scrutiny_area) {
       updateFormValue("scrutiny_fee_amount", (form?.scrutiny_area * rate?.scrutinizing_rate))
    }
  }, [form?.scrutiny_area]);

  useEffect(() => {
    if (form?.building_control_area) {
       updateFormValue("building_control_fee_amount", (form?.building_control_area * rate?.building_control_inspection_rate))
    }
  }, [form?.building_control_area]);

  useEffect(() => {
    if (form?.boundary_wall_area) {
       updateFormValue("boundary_wall_fee_amount", (form?.boundary_wall_area * rate?.boundary_wall_rate))
    }
  }, [form?.boundary_wall_area]);

  useEffect(() => {
    if (form?.fuel_station_area) {
       updateFormValue("fuel_station_fee_amount", (form?.fuel_station_area * rate?.fuel_station_rate))
    }
  }, [form?.fuel_station_area]);

  useEffect(() => {
    if (form?.telecom_mast_area) {
       updateFormValue("telecom_mast_fee_amount", (form?.telecom_mast_area * rate?.telecom_mast_rate))
    }
  }, [form?.telecom_mast_area]);


  useEffect(() => {
    if (!form?.administration && occupational_permit) {
       updateFormValue(
        "administration",
        (userData?.role_details?.can_physical_planning_division === 1 ||
        userData?.role_details?.can_physical_planning_division === true) &&  
        (userData?.role_details?.can_physical_planning === 0 ||
          userData?.role_details?.can_physical_planning === false) ? 4 :
        occupational_permit?.municipality_administration)
    }
  }, [occupational_permit]);

  const administration_options = [
    {
      id: occupational_permit?.municipality_administration,
      name: "Municipality",
    },
    {
      id: 4,
      name: "Town Council",
    },
  ]
  return (
    <>
      <form id={formId} onSubmit={onSubmit}>
        <div className="panel panel-primary">
          <div className="panel-heading">
            <div className="panel-title">Details</div>
          </div>
          <div className="panel-body">
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <FormInput
                    label="Building Classification"
                    type="text"
                    name="building_classification"
                    value={
                      occupational_permit?.classification_of_building_details
                    }
                    readonly="readonly"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <FormInput
                    label="Rating Zone"
                    type="text"
                    name="rating"
                    value={ratingZone}
                    readonly="readonly"
                  />
                </div>
              </div>
            </div>
            <Visibility 
              show={
                (userData?.role_details?.can_physical_planning === 1 ||
                userData?.role_details?.can_physical_planning === true) &&
                occupational_permit?.municipality_administration === 3
              }
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <SelectInput
                      label="Do yo want to use District rates of Town Council rates"
                      name="administration"
                      options={(administration_options || []).map((sup) => ({
                        label: sup.name,
                        value: sup.id,
                      }))}
                      onChange={(value) =>
                        updateFormValue("administration", value)
                      }
                      value={form.administration || ""}
                      required
                    />
                  </div>
                </div>
              </div>
            </Visibility>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <FormInput
                    label="Rate"
                    type="text"
                    name="rate"
                    value={rate?.rate}
                    onChange={onChange}
                    readonly="readonly"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <FormInput
                    label="Number of Blocks"
                    type="number"
                    name="number_building_blocks"
                    value={
                      parseInt(
                        occupational_permit?.num_blocks_fully_completed
                      ) +
                      parseInt(
                        occupational_permit?.num_blocks_partially_completed
                      )
                    }
                    onChange={onChange}
                    readonly="readonly"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <FormInput
                    label="Duration of Occupation Permit (In Years)"
                    type="number"
                    name="duration"
                    value={form?.duration}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="panel panel-primary">
          <div className="panel-heading">
            <div className="panel-title">Scrutinizing and Inspection Fees</div>
          </div>
          <div className="panel-body">
            {/* <div className="row">
              <div className="col-sm-12">
                <SelectInput
                  label="Scrutinizing of drawings, plans and documents including verifying of applications for occupation permits"
                  name="scrutiny_fee"
                  options={(options || []).map((sup) => ({
                    label: sup.name,
                    value: sup.id,
                  }))}
                  onChange={(value) => updateFormValue("scrutiny_fee", value)}
                  value={form.scrutiny_fee || ""}
                  required
                />
              </div>
              <Visibility show={form.scrutiny_fee === YES}>
                <div className="col-sm-12">
                  <div className="form-group">
                    <FormInput
                      label="Amount"
                      type="number"
                      name="scrutiny_fee_amount"
                      onChange={onChange}
                      value={form.scrutiny_fee_amount}
                    />
                  </div>
                </div>
              </Visibility>
            </div> */}

            <div className="row">
              <div className="col-sm-12">
                <SelectInput
                  label="Building control and inspection fee"
                  name="building_control_fee"
                  options={(options || []).map((sup) => ({
                    label: sup.name,
                    value: sup.id,
                  }))}
                  onChange={(value) =>
                    updateFormValue("building_control_fee", value)
                  }
                  value={form.building_control_fee || ""}
                  required
                />
              </div>
              <Visibility show={form.building_control_fee === YES}>
                <div className="col-sm-12">
                  <div className="form-group">
                    <FormInput
                      label="Building Control Area"
                      type="building_control_area"
                      name="building_control_area"
                      onChange={onChange}
                      value={form?.building_control_area}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <FormInput
                      label="Amount"
                      type="number"
                      name="building_control_fee_amount"
                      onChange={onChange}
                      value={form.building_control_fee_amount}
                      readonly="readonly"
                    />
                  </div>
                </div>
              </Visibility>
            </div>

            {/* <div className="row">
              <div className="col-sm-12">
                <SelectInput
                  label="Boundary Wall"
                  name="boundary_wall"
                  options={(options || []).map((sup) => ({
                    label: sup.name,
                    value: sup.id,
                  }))}
                  onChange={(value) => updateFormValue("boundary_wall", value)}
                  value={form.boundary_wall || ""}
                  required
                />
              </div>
              <Visibility show={form.boundary_wall === YES}>
                <div className="col-sm-12">
                  <div className="form-group">
                    <FormInput
                      label="Boundary Wall Area"
                      type="boundary_wall_area"
                      name="boundary_wall_area"
                      onChange={onChange}
                      value={form?.boundary_wall_area}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <FormInput
                      label="Amount"
                      type="number"
                      name="boundary_wall_fee_amount"
                      onChange={onChange}
                      value={form.boundary_wall_fee_amount}
                      readonly="readonly"
                    />
                  </div>
                </div>
              </Visibility>
            </div> */}

            <div className="row">
              <div className="col-sm-12">
                <SelectInput
                  label="Fuel Station"
                  name="fuel_station"
                  options={(options || []).map((sup) => ({
                    label: sup.name,
                    value: sup.id,
                  }))}
                  onChange={(value) => updateFormValue("fuel_station", value)}
                  value={form.fuel_station || ""}
                  required
                />
              </div>
              <Visibility show={form.fuel_station === YES}>
                <div className="col-sm-12">
                  <div className="form-group">
                    <FormInput
                      label="Fuel Station Area"
                      type="fuel_station_area"
                      name="fuel_station_area"
                      onChange={onChange}
                      value={form?.fuel_station_area}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <FormInput
                      label="Amount"
                      type="number"
                      name="fuel_station_fee_amount"
                      onChange={onChange}
                      value={form.fuel_station_fee_amount}
                      readonly="readonly"
                    />
                  </div>
                </div>
              </Visibility>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <SelectInput
                  label="Telocommunication Mast"
                  name="telecom_mast"
                  options={(options || []).map((sup) => ({
                    label: sup.name,
                    value: sup.id,
                  }))}
                  onChange={(value) => updateFormValue("telecom_mast", value)}
                  value={form.telecom_mast || ""}
                  required
                />
              </div>
              <Visibility show={form.telecom_mast === YES}>
                <div className="col-sm-12">
                  <div className="form-group">
                    <FormInput
                      label="Telocommunication Mast Area"
                      type="telecom_mast_area"
                      name="telecom_mast_area"
                      onChange={onChange}
                      value={form?.telecom_mast_area}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <FormInput
                      label="Amount"
                      type="number"
                      name="telecom_mast_fee_amount"
                      onChange={onChange}
                      value={form.telecom_mast_fee_amount}
                      readonly="readonly"
                    />
                  </div>
                </div>
              </Visibility>
            </div>
          </div>
        </div>
        <div className="panel panel-primary">
          <div className="panel-heading">
            <div className="panel-title">Permit Fees</div>
          </div>
          <div className="panel-body">
            {data?.map((item, index) => (
              <>
                <div className="row">
                  <div className="col-sm-6">
                    <SelectInput
                      label="Permit Fee Types"
                      name="permit_fee_type"
                      options={(permit_fees || []).map((sup) => ({
                        percentage: sup?.permit_fees_type_details?.percentage,
                        label: sup?.permit_fees_type_details?.name,
                        value: sup?.permit_fees_type_details?.id,
                      }))}
                      onChange={(value, obj) =>
                        handlePermitTypeChange(
                          value,
                          "permit_fee_type",
                          index,
                          obj
                        )
                      }
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <FormInput
                        label="Quantity ( M2 / LM )"
                        type="number"
                        name="quantity"
                        onChange={(event) =>
                          handleQuantityChange(
                            event,
                            "quantity",
                            index,
                            rate?.rate
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="form-group">
                      <FormInput
                        label="Rate"
                        type="number"
                        name="rate"
                        value={rate?.rate}
                        readonly="readonly"
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <FormInput
                        label="Percentage"
                        type="number"
                        name="percentage"
                        value={data[index]?.percentage}
                        readonly="readonly"
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <FormInput
                        label="Amount"
                        type="number"
                        name="amount"
                        value={data[index]?.amount}
                        readonly="readonly"
                      />
                    </div>
                  </div>
                </div>
                {data?.length > 1 ? (
                  <div className="row">
                    <div className="col-sm-12">
                      <div style={{ float: "right", narginBottom: 200 }}>
                        <button
                          type="btn"
                          className="btn btn-success"
                          onClick={(event) => deleteFee(event, index)}
                        >
                          Remove Fee
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            ))}
            <div>
              <div>
                <button
                  type="btn"
                  className="btn btn-success"
                  onClick={(event) => handleFee(event)}
                >
                  Add Fee
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="panel panel-primary">
          <div className="panel-heading">
            <div className="panel-title">Series or rows of buildings</div>
          </div>
          <div className="panel-body">
            <div className="row">
              <div className="col-sm-12">
                <SelectInput
                  label="Applicable"
                  name="applicable"
                  options={(options || []).map((sup) => ({
                    label: sup.name,
                    value: sup.id,
                  }))}
                  onChange={(value) => updateFormValue("applicable", value)}
                  value={form.applicable || ""}
                  required
                />
              </div>
            </div>
            <Visibility show={form.applicable === YES}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <FormInput
                      label="Gross Floor Area"
                      type="number"
                      name="gross_floor_area"
                      onChange={(event) =>
                        seriesOccupationalCalculationHandler(event)
                      }
                      value={form.gross_floor_area}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <FormInput
                      label="Amount"
                      type="number"
                      name="amount"
                      value={seriesAmount}
                      readonly="readonly"
                    />
                  </div>
                </div>
              </div>
            </Visibility>
          </div>
        </div>
        <div className="panel panel-primary">
          <div className="panel-heading">
            <div className="panel-title">Other Fees</div>
          </div>
          <div className="panel-body">
            <div className="row">
              <div className="col-sm-12">
                <SelectInput
                  label="Any Other Fees"
                  name="any_other_fees"
                  options={(options || []).map((sup) => ({
                    label: sup.name,
                    value: sup.id,
                  }))}
                  onChange={(value) => updateFormValue("any_other_fees", value)}
                  value={form.any_other_fees || ""}
                  required
                />
              </div>
            </div>

            {form.any_other_fees === YES ? (
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <FormInput
                      label="Specify"
                      type="text"
                      name="specify"
                      onChange={onChange}
                      value={form?.specify}
                      readonly={form.any_other_fees === "0" ? "readonly" : null}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <FormInput
                      label="Amount"
                      type="number"
                      name="amount_any_other_fee"
                      value={form?.amount_any_other_fee}
                      onChange={onChange}
                      readonly={form.any_other_fees === "0" ? "readonly" : null}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="panel panel-primary">
          <div className="panel-heading">
            <div className="panel-title">Sub Total</div>
          </div>
          <div className="panel-body">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <FormInput
                    label="Sub Total"
                    type="number"
                    name="sub_total"
                    value={subTotal}
                    readonly="readonly"
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <FormInput
                    label="VAT (18%)"
                    type="number"
                    name="vat"
                    value={vat}
                    readonly="readonly"
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <FormInput
                    label="Total Amount"
                    type="number"
                    name="total_amount"
                    value={Math.round(totalAmount)}
                    readonly="readonly"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

OccupationalPermitAssessment.propTypes = {};

export default OccupationalPermitAssessment;
